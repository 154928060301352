// ------------------------------------------------------------------------- MOBILE UI
#subnav-toggle {
  background: @white-darken-01;
  .clearfix;
  .visible-xs;

  .visible-titles {
    .clearfix;
    height: 56px;

    .caret {
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 8px solid @taupe-00;
      margin-top: 9px;
      .pull-right;
    }
    .dropdown-title {
      float: right !important;
      display: block;
      color: @taupe-00;
      font-size: 14px;
      margin-top: 15px;
    }
  }

  .section-title {
    text-transform: capitalize;
    margin: 5px 0 0 0;
    font-size: 22px;
    display: block !important;
    color: @taupe-00;
  }

  .sidebar-nav-active {
    text-transform: uppercase;
    margin: 0;
    color: @taupe-00;
    .kern-wide;
    font-size: 80%;
  }
}
#subnav-items {
  display: none;
  margin: 0 -15px;
  background: @white-darken-03;

  .sidebar-nav {
    padding: (@line-height-computed * 0.5) 0 10px 0;
    background: none;
    border: none;
    margin-bottom: 0;

    li.active a:before, li a:before {
      display: none;
    }

    li.active {
      padding: 0;
    }
    .nav-title {
      display: none;
    }
    .nav {
      margin-left: 0;
    }
    li.section-title {
      height: 1px;
      overflow: hidden;
    }

  }
  .promotions {
    display: none;
  }
}

.account-tabs {

  li {
    @media (max-width: @screen-xs-max) {
      float: none;
      border: 1px solid @border-color;
    }
  }
  li a {
    @media (max-width: @screen-xs-max) {
      margin: 0;
      border: none;
    }
  }

  li.active a,
  li.active a:hover,
  li.active a:focus,
  li.active a:active {
    @media (max-width: @screen-xs-max) {
      border: none;
      background: @muted-background;
    }
  }
}

#profile-form {
  h3 {
    margin: 2em 0 1em;
  }
}
.form-group-buttons {
  margin-top: 2em;
}

.form-control.no-shadow {
  .box-shadow(none);
}
.select-group {
  select {
    .form-control;
    .make-xs-column(3);
    margin-right: 5px;
  }
}
.mobile-nav {
  margin-top: @line-height-computed;
}

.cursor-pointer {
  cursor: pointer;
}
