@charset "UTF-8";

@font-face {
  font-family: "tfl-global";
  src:url("/static/fonts/tfl-global.woff") format("woff");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "tfl-global" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="ti-"]:before,
[class*=" ti-"]:before {
  font-family: "tfl-global" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ti-signal:before {
  content: "\46";
}
.ti-tv:before {
  content: "\47";
}
.ti-lifebuoy:before {
  content: "\50";
}
.ti-info-circle:before {
  content: "\52";
}
.ti-tunein:before {
  content: "\53";
}
.ti-fire:before {
  content: "\54";
}
.ti-station:before {
  content: "\56";
}
.ti-audionow:before {
  content: "\55";
}
.ti-book:before {
  content: "\32";
}
.ti-book-bookmark:before {
  content: "\33";
}
.ti-check:before {
  content: "\24";
}
.ti-down-open:before {
  content: "\25";
}
.ti-down-open-big:before {
  content: "\26";
}
.ti-down-open-mini:before {
  content: "\27";
}
.ti-left-open-big:before {
  content: "\28";
}
.ti-right-open-big:before {
  content: "\29";
}
.ti-envelope:before {
  content: "\2a";
}
.ti-paper-plane:before {
  content: "\6c";
}
.ti-play:before {
  content: "\6e";
}
.ti-pause:before {
  content: "\2e";
}
.ti-search:before {
  content: "\2c";
}
.ti-soundcloud:before {
  content: "\49";
}
.ti-video:before {
  content: "\3c";
}
.ti-social-amazon:before {
  content: "\48";
}
.ti-quote-right:before {
  content: "\77";
}
.ti-quote-left:before {
  content: "\78";
}
.ti-alert:before {
  content: "\57";
}
.ti-info:before {
  content: "\58";
}
.ti-lock:before {
  content: "\5b";
}
.ti-audionow-bk:before {
  content: "\5e";
}
.ti-attention:before {
  content: "\7b";
}
.ti-refresh:before {
  content: "\7c";
}
.ti-share-1:before {
  content: "\7d";
}
.ti-user:before {
  content: "\5e";
}
.ti-podcast:before {
  content: "\6f";
}
.ti-share:before {
  content: "\77";
}
.ti-share2:before {
  content: "\78";
}
.ti-roku:before {
  content: "\48";
}
.ti-bars:before {
  content: "\31";
}
.ti-android:before {
  content: "\34";
}
.ti-angle-left:before {
  content: "\f104";
}
.ti-angle-right:before {
  content: "\f105";
}
.ti-apple:before {
  content: "\39";
}
.ti-calendar:before {
  content: "\21";
}
.ti-phone-square:before {
  content: "\22";
}
.ti-phone:before {
  content: "\23";
}
.ti-chevron-right:before {
  content: "\3e";
}
.ti-chevron-left:before {
  content: "\5c";
}
.ti-chevron-up:before {
  content: "\72";
}
.ti-chevron-down:before {
  content: "\71";
}
.ti-check-square:before {
  content: "\e003";
}
.ti-camera-retro:before {
  content: "\e004";
}
.ti-credit-card:before {
  content: "\e005";
}
.ti-times-circle:before {
  content: "\e006";
}
.ti-download:before {
  content: "\e007";
}
.ti-usd:before {
  content: "\e008";
}
.ti-exclamation-triangle:before {
  content: "\e009";
}
.ti-edit:before {
  content: "\e00a";
}
.ti-external-link:before {
  content: "\e00b";
}
.ti-facebook:before {
  content: "\e00c";
}
.ti-file-o:before {
  content: "\e00d";
}
.ti-folder-o:before {
  content: "\e00e";
}
.ti-headphones:before {
  content: "\e00f";
}
.ti-instagram:before {
  content: "\e010";
}
.ti-linkedin:before {
  content: "\e011";
}
.ti-google-plus:before {
  content: "\e012";
}
.ti-lock-2:before {
  content: "\e013";
}
.ti-microphone:before {
  content: "\e014";
}
.ti-windows:before {
  content: "\e015";
}
.ti-mobile:before {
  content: "\e016";
}
.ti-tablet:before {
  content: "\e017";
}
.ti-thumb-tack:before {
  content: "\e018";
}
.ti-map-marker:before {
  content: "\e019";
}
.ti-life-buoy:before {
  content: "\e01a";
}
.ti-pinterest:before {
  content: "\e01b";
}
.ti-paper-plane-2:before {
  content: "\e01c";
}
.ti-quote-right:before {
  content: "\e01e";
}
.ti-quote-left:before {
  content: "\f10d";
}
.ti-angle-double-right:before {
  content: "\e020";
}
.ti-angle-double-left:before {
  content: "\e021";
}
.ti-rss:before {
  content: "\e022";
}
.ti-square-o:before {
  content: "\e023";
}
.ti-bookmark-o:before {
  content: "\e024";
}
.ti-twitter:before {
  content: "\e025";
}
.ti-youtube:before {
  content: "\e026";
}
.ti-shopping-cart:before {
  content: "\61";
}
.ti-whatsapp:before {
  content: "\62";
}
.ti-caret-up:before {
  content: "\63";
}
.ti-caret-down:before {
  content: "\64";
}
.ti-angle-up:before {
  content: "\65";
}
.ti-angle-down:before {
  content: "\66";
}
.ti-delete-circle:before {
  content: "\67";
}
.ti-addthis:before {
  content: "\69";
}
.ti-square-line:before {
  content: "\6a";
}
.ti-check-mark:before {
  content: "\6b";
}
.ti-question-circle:before {
  content: "\6d";
}
.ti-cloud-download:before {
  content: "\70";
}
.ti-folder:before {
  content: "\73";
}
.ti-link:before {
  content: "\74";
}
.ti-paypal:before {
  content: "\75";
}
.ti-envelope-o:before {
  content: "\76";
}
.ti-itunes:before {
  content: "\79";
}
.ti-googleplay:before {
  content: "\7a";
}
.ti-square:before {
  content: "\41";
}
.ti-laptop:before {
  content: "\42";
}
.ti-desktop:before {
  content: "\43";
}
.ti-print:before {
  content: "\44";
}
.ti-close-x:before {
  content: "\45";
}
.ti-map-marker-wide:before {
  content: "\4a";
}
.ti-oneplace:before {
  content: "\4b";
}
.ti-sirius:before {
  content: "\4c";
}
.ti-cloud-download-2:before {
  content: "\4d";
}
.ti-checkmark:before {
  content: "\4e";
}
.ti-truck:before {
  content: "\4f";
}
.ti-circle-thin:before {
  content: "\51";
}
.ti-prohibited:before {
  content: "\59";
}
.ti-filter:before {
  content: "\5a";
}
.ti-circle:before {
  content: "\35";
}
.ti-alexa-mark:before {
  content: "\30";
}
.ti-google-home-mark:before {
  content: "\36";
}
.ti-google:before {
  content: "\37";
}
.ti-pencil:before {
  content: "\38";
}
.ti-youtube-play:before {
  content: "\2b";
}
.ti-cd:before {
  content: "\68";
}
.ti-cc:before {
  content: "\2d";
}
.ti-spotify:before {
  content: "\2f";
}
