// launch button
.look-inside .look-inside_launch
{
  cursor:pointer;
  text-align:center;

  img{
    position:relative;
    z-index:0;
    height: auto;
    max-height: 270px;
    width: auto;
    max-width: 100%;
    transition:transform 0.4s;
  }

  &:hover img
  {
    transform:scale(1.05);
  }

}
.look-inside_launch
{
  .btn{
    position:relative;
    z-index:1;
    margin-bottom:12px;
    .tfl-btn-primary;

    i{
      color:@white-00 !important;
      font-size:1em !important;
    }
  }
}

// modal window
.look-inside .look-inside_window
{
  text-align:center;

  .modal-dialog{
    max-width:900px;
  }
  .modal-body{
    padding:50px;

    @media (max-width: @screen-xs-max){
      padding:10px;
    }
  }

  img{
    display:inline-block;
    max-width:100%;
    width:auto !important;
    max-height: 90vh !important;

    margin-bottom:10px;
    border-top:2px solid @white-darken-05;
    border-radius:0px;
  }
}
.look-inside--single .look-inside_window
{
  img{
    max-height:80vh !important;
  }
  .look-inside_scroll{
    display:none;
  }
  .look-inside_close{
    display:none;
  }
}
.look-inside--multi .look-inside_window
{
  .look-inside_scroll{
    position: fixed;
    left: 50%;
    bottom: 10%;
    z-index: 5000;
    margin: 0 auto;

    transition:bottom 0.5s;
  }
  .look-inside_scroll:before{
    content:"\q";

    display:block;
    position: relative;
    left: -50px;
    width: 100px;
    padding: 10px;
    background: @white-00;
    cursor:pointer;
    opacity: 0.8;
    border-radius: 50px;
    color:@grey-darken-02;
    text-align: center;
    font-family:"tfl-global";
  }
  &.look-inside--scrolled .look-inside_scroll{
    bottom:-10%;
  }

  .look-inside_close{
    float:none;
    display:block;
    max-width:100px;
    margin:1em auto 1em auto;
    cursor:pointer;
    padding:10px;

    opacity:0.8;
    border-radius:50px;
    color:@grey-darken-02;
  }
}

// collapsible description
.look-inside-description
{
  margin-bottom:1em;

  .look-inside-description_button
  {
    margin-bottom:20px;
    transition: margin 0.4s;

    &:before
    {
      content:"Hide ";
    }

    &.collapsed
    {
      margin-bottom:0px;

      &:before
      {
        content:"Show ";
      }
    }
  }


  .look-inside-description_copy
  {
    text-align:left;
    font-size:15px;

    p
    {
      font-size:unset;
    }
    *
    {
      text-align:unset;
      font-family:unset;
      font-size:unset;
    }
  }

}
