@import '../lib/bootstrap-3.2.0/bootstrap.less';
@import '../../js/responsive/lib/mediaelement/mediaelementplayer.less';
@import '../lib/lesshat/lesshat';
@import '../base/tfl-variables.less';
@import 'my-mixins.less';
@import (inline) '../lib/jquery-ui-1.12.1.custom.css';
@import 'jquery-datepicker';
@import 'joyride-custom';
@import "fontastic.less";
@import "global-imports-landing";
@import "tp-stages.less";
@import "look-inside.less";
@import "anniversary.less";

@charset "utf-8";

body{
  overflow-x: hidden; /* prevents horizontal scroll bars */
  -webkit-tap-highlight-color: rgba(0,0,0,0); /* disable webkit tap highlight */
  background-color: @brand-primary;
}
.nowrap {
 white-space: nowrap !important;
}
.photo {
  &.pull-left {
    margin: 15px 25px 15px 0;
    max-width: 33%;
  }
  &.pull-right {
    margin: 15px 0 15px 25px;
    max-width: 33%;
  }
  &.thumbnail {
      margin-top:0;
    }
  @media (min-width: @screen-md-min) {
    width: auto;
  }
}

hr.double-line {
  border: none;
  border-bottom: 1px solid @border-color;
  position: relative;

  &:before {
    content: " ";
    position: absolute;
    z-index: 1;
    top: 4px;
    left: 0;
    right: 0;
    bottom: 5px;
    border-bottom: 1px solid @border-color;
  }
}

img {
  border-radius: 2px;
  &[src="/static/uploads/TFL_HorizontalRule.jpg"],
  &[src="/static/uploads/TFLHorzRuleNEW.jpg"] {
    display: none;
  }
}

blockquote {
  font-size: 1em;
  margin-top: 2em;
  margin-bottom: 2em;
  border-left-color: @border-color;
  &.pull-quote {
    .make-sm-column(6);
    .make-lg-column(4);
    border: none;
    &.pull-right {

      p {
        font-family: @font-family-secondary;
        font-size: 2em;
        color: @brand-primary;

        line-height: 1.1em;
      }
    }
    cite {
      font-family: @font-family-base;
      font-style: normal;
      font-size: 1em;
    }
  }
}


video { display: block; }
p.lead {
  font-family: @font-family-secondary;
  font-size: 1.7em;
  color: @lead-color;
}

hr {
  border: 0;
  border-top: 1px solid @hr-border;
  clear: both;
  margin-top: @grid-gutter-width*1.5;
  margin-bottom: @grid-gutter-width*1.5;
}

.article-title {
  @media (max-width: @screen-xs-max) {
    margin: @line-height-computed 0;
  }

  a {
    color: @title-color;

    &:hover {
      color: lighten(@title-color, 10%);
    }
  }
}

.footnote {
  color: @muted-text;
  .small;
  position: relative;
  top:-4px;
}

 // See global-imports-landing.less for print-only and hidden-print

.smallprint {
  color: @muted-text;
  display: block;
  margin: @grid-gutter-width 0;
  p {
    font-size: 110%;
  }
}

.btn-primary-call {
  padding-left: 13px;
  padding-right: 13px;
  em {
    letter-spacing: 0;
    text-transform: none;
    font-family: @font-family-slab;
    font-style: italic;
    font-weight: 600;
  }
}

.bullets {
  li {
    padding-left: 15px;
    position: relative;
    line-height: 1.3 !important;
    margin-bottom: .5em !important;
    &:before {
      .ti;
      content: "\F105";
      font-size: 15px;
      color: @muted-text;
      .opacity(.8);
      margin-right: 6px;
      top: 3px;
      position: absolute;
      left:-3px;
      ;
    }
  }
}

.label {
  border-radius: 2px;
  padding: 5px 8px 4px;
  text-transform: uppercase;
}

.featured-group {
  .well;
  padding: 10px @grid-gutter-width;
  margin-bottom: @grid-gutter-width*1.5;
  #gradient.vertical(lighten(@well-bg, 3%), @well-bg);
  h3 {
    color: @muted-text;
    font-size: 1.1em;
    text-transform: uppercase;
    font-family: @font-family-base;
    font-weight: 100;
    margin-bottom: 1em;
    strong {
      font-weight: 1em;
      font-family: @font-family-secondary;
      font-weight: 600;
      color: @muted-text;
    }
    .pull-right {
      font-size: .6em;
      line-height: 3.2em;
      display: inline-block;
      position: relative;
      top: -8px;
    }
  }
  a {
    color: @muted-text;
  }
  .bullets {
    margin-top: 0;
    li {
      margin-bottom: 0;
    }
  }
}

.shadow-longer {
  box-shadow: 0 2px 1px rgba(0,0,0,0.07),
              0 4px 2px rgba(0,0,0,0.07),
              0 8px 4px rgba(0,0,0,0.07),
              0 16px 8px rgba(0,0,0,0.07),
              0 32px 16px rgba(0,0,0,0.07);
}


// ------------------------------------------------------------------------- Adslice

#adslice {
  text-align: center;
  background: darken(@brand-success, 4%);
  color: @white-00;
  padding-top: 2px;
  padding-bottom: 2px;
  font-family: @font-family-secondary;
  line-height: 2em;
  font-size: 1em;
  letter-spacing: .5px;
  a {
    color: @white-00;
    display: block;
  }
  .btn-primary {
    background-color: lighten(@brand-success, 4%);
    text-transform: uppercase;
    font-weight: 600;
    position: relative;
    top:-1px;
    letter-spacing: 1px;
    font-weight: bold;
    font-family: @font-family-base;
    margin-left: 4px;
  }
}

.text-inverse {
  p, p.lead {
    color: @white-00;
  }
}
.text-lg {
  font-size: 1.2em;
}

.mobile-scroll-y {
  overflow-y: scroll;
  .column1 {
    @media (max-width: @screen-xs-max) {
      max-width: 100px;
      word-wrap:break-word;
    }
  }
}

.blog-leader-wrapper,
.archive-description-wrapper {
  p.lead {
    font-family: @font-family-base;
    font-size: @font-size-base;
    color: @text-color;
    font-weight: normal;
    line-height: @line-height-base*1.3;
  }
}

.pagination {
  .fa, [class^="ti-"] {
    font-size: 16px !important;
  }
  .active {
    > a {
      background: @brand-primary;
      color: @white-00;
    }
  }
}
.pagination-sm {
  display: block;
  clear: both;
  & > li {
    > a {
      padding-left: 9px;
      padding-right: 9px;
    }
  }
}


.btn-show-more {
  background: transparent;
  border: none;
  display: block;
  font-size: 20px;
}

.spinner {
  width: 40px;
  height: 40px;
  margin: 100px auto;
  background-color: @muted-text;

  border-radius: 100%;
  -webkit-animation: scaleout 1.0s infinite ease-in-out;
  animation: scaleout 1.0s infinite ease-in-out;
}

@-webkit-keyframes scaleout {
  0% { -webkit-transform: scale(0.0) }
  100% {
    -webkit-transform: scale(1.0);
    opacity: 0;
  }
}

@keyframes scaleout {
  0% {
    transform: scale(0.0);
    -webkit-transform: scale(0.0);
  } 100% {
    transform: scale(1.0);
    -webkit-transform: scale(1.0);
    opacity: 0;
  }
}

// ------------------------------------------------------------------------- Forms Misc


form {
  .radio-group-wrapper {
    ul {
      margin-top: 3px;
      label {
        font-weight: normal;
      }
    }
  }
  .checkbox-group-wrapper {
    padding-top: 7px;
  }
  .free-copy {
    .input-col,
    .input-wrapper {
      padding-top: 3px;
    }
  }
  .help-block {
    color: @taupe-darken-01;
  }
  @media (max-width: @screen-xs-max) {
    .form-group {
      .row {
        > div {
          .add-margin-bottom-except-last;
        }
      }
    }
  }
}

.form-errors__details {
  display: flex;
  justify-content: flex-start;
  p {
    flex: 1 1 auto;
    margin: 0;
  }
  span {
    flex: 0 0 auto;
    align-self: flex-end;
  }
  @media (max-width: @screen-sm-max) {
    flex-direction: column;
    p, span, a {
      width: 100%;
      text-align: center;
    }
  }
}


label {
  display: block;
}

.radio {
  ul {
    margin-bottom: 0;
    li {
      display: inline;
      input[type="radio"] {
        margin: 0 3px 0 -22px;
        position: relative;
        display: inline-block;
      }
      label {
        .btn;
        color: @btn-default-color;
        border-color: @muted-background;
        padding-left: 32px;
        margin-bottom: 4px;
        white-space: normal;
        text-align: left;
        &:hover {
          border-color: @blue-00;
        }
        &.checked11 {
          .btn-default;
          border-color: @blue-00;
        }
      }
    }
  }
}

.form-wrapper {
  .well.alert-info {
    position: relative;
    background-color: @tan-lighten-05;
    border: none;
    .fa, [class^="ti-"] {
      position: absolute !important;
      top: 5px;
      right: 8px;
      .rotate(30);
      font-size: 18px;
    }
  }
}

.form-control-feedback-success {
  color: @brand-success;
}

.alert-notification {
  background-color: transparent;
  color: @green-lighten-05;
  border: 2px solid @green-lighten-05;
  font-size: 18px;
  &.small {
    margin: 10px 25%;
    @media (max-width: @screen-xs-max) {
      margin: 0;
    }
  }
  .close {
    font-size: 16px;
    padding: 0;
    line-height: 16px;
    vertical-align: middle;
    margin: 5px 0 0 0;
    opacity: 0.4;
    &:hover {
      opacity: 0.9;
    }
  }

}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.extra-padding-left {
  padding-left: (@grid-gutter-width * 0.5);

  @media (min-width: @screen-md-min) {
    padding-left: (@grid-gutter-width * 1.2);
  }
}
.extra-padding-right {

}

.section-title {
  text-transform: uppercase;
  font-size: @font-size-base;
  color: @muted-text;
  margin-top: 10px;
  margin-bottom: 10px;
  letter-spacing: .04em;
  .caret {
    @media (min-width: @screen-sm-min) {
      .hidden;
    }
  }

  a {
    color: @muted-text;
  }
}

.title-divider {
  border-bottom: 1px solid @border-color;
  padding-bottom: ((@line-height-computed * .5) - 1);
}

.title-sm {
  font-size: floor(@font-size-large * 1.25);
  font-weight: 400;
}

.title-wrapper {
  .make-xs-column(12);
}

.kern-wide {
  letter-spacing: 1px;
}

.pipe {
  opacity: .4;
}

.tooltip {
  min-width: 100px;
}

i[data-toggle="tooltip"], i[data-toggle="popover"] {
  cursor: pointer;
}

.border-wash {
  border-color: rgba(255, 255, 255, 0) !important;
}
.form-section-title {
  padding-top: @line-height-computed;
  margin: @line-height-computed 0;
  text-transform: uppercase;
  .kern-wide;
  color: @form-section-title;
  border-top: 1px dotted @border-color;
}

.muted-text-alt {
  color: @muted-text;
  strong {
    color: @blue-lighten-08;
  }
}

.form-control {
  .placeholder(@muted-text);
}

#store-donation-product {
  .form-group-donation-amount {
    .make-sm-column(6);
  }
  .donation-product-thumb {
    .make-sm-column(4);
    .make-sm-column-offset(1);
  }
  .donation-product-options {
    .make-sm-column(7);
  }
  .form-section-title {
    @media (min-width: @screen-sm-min) {
      padding-top: 60px;
      margin-bottom:0;
    }
  }
}

// ------------------------------------------------------------------------- Global Navigation
// ---------------------------------------------------- Utility Navigation
.nav-outer-wrapper {
  // background: @primary-color url('/static/images/responsive/bg-header-area.png');
  // TODO remove texture so we can see the color changes
  background: @primary-color;
}
.nav-outer-wrapper.day-browser-affixed {
  margin-bottom: 45px;
}
.nav-utility-outer-wrapper {
  background: @primary-color;
  font-size: @font-size-small;
  line-height: @line-height-computed;
  border-bottom: 1px solid @blue-lighten-01;
  .hidden-xs;
  @media (max-width: @screen-sm-max) {
    // Tablet portrait view
    text-align: center;
    .nav-pills {
      > li {
        display: inline-block;
      }
    }
  }

  .section-title span
  {
    display: block;
    padding: 8px 5px 0;
    font-size: 10px;
    border-top: 1px solid @blue-lighten-02;
    color: @white-00;
    opacity: 0.6;
  }

  .nav {
    .pull-right;
    font-size: @font-size-small;
    @media (min-width: @screen-md-min) {
      margin-right: 0px;
    }

    li {
      font-size: 13px;
    }

    > li {

      > a {
        padding:12px;
        &:hover {
          color: @white-00;
          > i:after {
            .opacity(1) !important;
          }
        }
        [class^="ti-"], [class*=" ti-"] {
          position: relative;
          top: 2px;
        }
        .ti-chevron-down {
          font-size: 9px;
        }
      }
      &.cart-filled {
        > a {
          .gradient-greenout;
          color: @white-00;
        }
      }
      & .cart-contents
      {
        display:none;
      }
      &.cart-filled
      {
        div.cart-contents
        {
          display:block;
        }
        span.cart-contents
        {
          display:inline;
        }
        .cart-empty
        {
          display:none;
        }
      }
      &.dropdown {
        .header-chevrons;
        > a {
          > i:after {
            bottom: 6px;
          }
        }
      }
    }

    a {
      color: @nav-utility-link;
      border-radius: 0;
      font-weight: 700;

      &:hover {
        background: lighten(@nav-utility, 1%);
        color: @nav-utility-link;
      }
      &:focus {
        background: lighten(@nav-utility, 5%);
      }
      [class^="ticon-"] {
        .opacity(.7);
      }
      .ti-shopping-cart,
      .ti-shopping-cart {
        font-size: 14px;
      }
    }
    .pipe:before {
      content: "";
      display: block;
      height:20px;
      width: 1px;
      border-left: 1px solid @blue-lighten-02;
      position: absolute;
      left:0;
      top:8px;
      z-index: 1;
    }
  }

  .btn-donate {

    background: @nav-utility-donate-background;
    font-weight: bold;


    & > a {
      .kern-wide;
      text-transform: uppercase;
      // color: @nav-utility-donate-link;

      &:hover {
        background: @support-color;
        // color: lighten(@support-color, 40%);
      }
    }
  }
  .dropdown-menu, .dropdown.open > a {
    background-color: @nav-dropdown-background;
  }
  .dropdown-menu {
    border: none;
    padding: 8px;
    top: 90%;
    > li {
      > a {
        padding-left: 5px;
        padding-right: 5px;
        &:hover {
          background-color: @blue-lighten-01;
        }

      }
    }
  }
}
#dropdown-search,
#dropdown-search:hover,
#dropdown-search:focus {
  width:106px;
  border-color: transparent;
}

.nav-utility-support {
  .make-row();

  @media (min-width: @screen-md-min) {
    padding: 0;
  }
}
.nav-utility-wrapper {
  .make-xs-column(12);

  .sign-in-register:active,
  .sign-in-register:focus {
    background: transparent;
  }

  .open .sign-in-register,
  .open .sign-in-register:active,
  .open .sign-in-register:focus {
    background: lighten(@nav-utility, 3%);
    border: none;
  }

}

.cart-options {

  .btn-cart:active,
  .btn-cart:focus,

  &.open .btn-cart:active,
  &.open .btn-cart:focus {
    background: lighten(@nav-utility, 3%);
    border: none;
  }


  .dropdown-cart {
    right: 0;
    left: -230px;
    top: 39px;
    width: 300px;
    padding: @line-height-computed;
    padding-bottom: (@line-height-computed * .5);
    border-radius: 0 0 3px 3px;
    border: 0;
    background: lighten(@nav-utility, 3%);
    .box-shadow(0 4px 3px fade(#000, 20%));
    color: @white-00;

    .btn {
      border-radius: 2px;
    }

    .btn-view-cart {
      margin-top: 14px;
      display: inline-block;
    }

    .btn-checkout {
      .btn-primary-call;
      color: @white-00;
      .pull-right;
      margin-top: 10px;

      &:hover {
        color: @white-00;
      }
    }
  }

  .cart-actions {
    border-top: 1px solid lighten(@nav-utility, 7%);
    margin-top: 5px;
  }
}

.form-sign-in {
  padding-bottom: @line-height-computed;
}

//---------------------------- Checkout process

.progress-block {
  margin: @line-height-computed 0;

  .progress {
    height:10px;
    margin:0 0 25px;
    border-radius:0;
    box-shadow:none;
    background: @muted-background;
    overflow:visible;
    border-radius: 10px;
  }
  .progress-bar {
    background: @cart-progress;
    box-shadow:none;
    position:relative;
    z-index:1;
    border-radius: 10px;
  }
  .progress-bar .cart {
    width:32px;
    height:26px;
    position:absolute;
    top:-11px;
    right:-3px;
    z-index:2;
    font-size: 36px;
    color: @cart-progress-marker;
  }
}

/**** cart stages ******************/
.progress-block
{
  padding:0 20px 0 20px;

  .steps
  {
    margin:0 -20px 0 -20px;
  }
}
.Stage1
{
  .progress-bar
  {
    width:10%;
  }
  .Step1
  {
    display:block;
    font-weight:bold;
  }
}

.Stage2
{
  .progress-bar
  {
    width:30%;
  }
  .Step2
  {
      display:block;
      font-weight:bold;
  }
}

.Stage3
{
  .progress-bar
  {
    width:50%;
  }
  .Step3
  {
    display:block;
    font-weight:bold;
  }
}

.Stage4
{
  .progress-bar
  {
    width:70%;
  }
  .Step4
  {
    display:block;
    font-weight:bold;
  }
}

.Stage5
{
  .progress-bar
  {
    width:100%;
  }
  .Step5
  {
    display:block;
    font-weight:bold;
  }
}


.steps {
  color: @muted-text;
  overflow:hidden;
  position: relative;
  .list-unstyled;

  @media (max-width: @screen-sm-min) {
    font-size: 12px;
  }
  @media (max-width: @screen-lg-min) {
    font-size: 14px;
  }
}
.steps li {
    .text-center;
    float: left;
    width:20%;
}
/*
.step-cart {width:19.2%;}
.step-shipping {width: 17%;}
.step-payment {width: 27%;}
.step-confirmation {width: 17.5%;}
.step-receipt {width: 16%;}
*/

.steps .active{font-weight: bold;}

.table-cart-container, .cart-functions-container {

  input[name='quantity']:focus
  {
    border-color: @support-color;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(147, 163, 68, 0.6);
    -moz-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(147, 163, 68, 0.6);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(147, 163, 68, 0.6);
  }

  @media (max-width: @screen-xs-max) {
    .btn-checkout {
      width: 100%;
      margin: 5px auto;
    }
    .btn-continue-shopping {
      width: 100%;
      margin: 5px auto;
    }
  }
  @media (min-width: @screen-sm-min) {
    .btn-checkout {
      .pull-right;

    }
  }
  @media (min-width: @screen-lg-min) {
    margin: 0 auto;
  }
  tbody {
    background-color: @white-00;
  }
}

.table-cart-container {
  thead {
    @media (max-width: @screen-xs-max) {
      display: none;
    }
  }
  tr {
    @media (max-width: @screen-xs-max) {
      border-top: 1px solid @border-color;
    }
  }

  td {
    @media (max-width: @screen-xs-max) {
      display: block;
      width: 100%;
      .text-left;
      border-top: none !important;
      .btn-remove {
        position: absolute;
        top:0px;
        right:0px;
        z-index: 2;
      }
      &.item-title {
        text-align: center;
        form
        {
          position:relative;
        }
      }
      &.item-price,
      &.item-quantity,
      &.item-total {
        width: 33%;
        display: inline-block;
        text-align: center;

      }
      &.item-quantity {
        form {
          display: inline-block;
        }
        input {
          float: none;
          margin: 0 auto;

        }
      }
    }
    &.item-price,
    &.item-quantity,
    &.item-total {
      line-height: 2em;
    }
    &.item-quantity {
      .form-control-number {
        width: 60px;
      }
    }
    .stock_status__wrapper {
      clear: both;
      white-space: wrap;
      p {
        font-size: 16px;
        line-height: 20px;
      }
    }


  }
  .table-footer {
    td {
      @media (max-width: @screen-xs-max) {
        text-align: right;
      }
    }

  }
  .product-thumb-cart {
    min-width: 100px;
    text-align: center;
    img {
      .img-responsive();
      @media (max-width: @screen-xs-max) {
        display: inline-block;
      }
    }
    @media (min-width: @screen-sm-min) {
      .pull-left;
      margin-right: 15px;
    }
  }


  #responsive-table h5.product-name
  {
    @media screen and (max-width: @screen-xs-max)
    {
      white-space:normal;
    }
  }

  .form-control {
    @media (max-width: @screen-xs-max) {
      width: 100px;
    }
  }

  .btn-update {
    .btn;
    .btn-primary-call;
    .btn-xs;
    color: @white-00;
  }

  @media (max-width: @screen-sm) {
    .dropdown-clear {
      .dropdown-menu {
        float: none;
        position: relative;
      }
    }
  }
}

.dropdown-clear {
  .dropdown-menu {
    .box-shadow(none);
    border: none;
    background: transparent;
  }
}

.co-product-name {
  font-weight: bold;
}
.co-price {
  color: @price-color;
  font-weight: bold;
}
.co-download {
  margin-top: 1em;
}
.co-quantity {
  .small;
}
.co-price-total {
  .co-price;
  font-size: @font-size-large;
}

.alert {
  padding: (@line-height-computed * 0.25) (@line-height-computed * 0.45);
}

.form-group .alert {
  margin: (@line-height-computed * 0.25) 0 (@line-height-computed * 0.5);
  > ul {
    margin:0;
  }
}

// -------
img.nav-icon
{
  width:20px;
  height:20px;
}
.nav-utility-outer-wrapper li.utility-account > a:hover
{
  background:none;
}
a:hover .account-badge::after
{
  background:@blue-lighten-09;
}
.account-badge
{
  position: relative;
  display: inline-block;
  width: 80px;
  height: 80px;
  margin-top:-35px;
  margin-bottom:-32px;
  color: @white-00;
  text-align: center;
  font-size: 27px;
  line-height: 80px;
  vertical-align: middle;
  text-transform:uppercase;

  &::before, &::after
  {
    content:" ";
    position:absolute;
    top:0;
    left:0;
    z-index:1;
    display:block;
    width:100%;
    height:100%;
    border-radius:50%;
    background:@primary-color;
    border:2px solid @blue-lighten-02;
  }
  &::after
  {
    top:7%;
    left:7%;
    width:86%;
    height:86%;
    border:none;
    background:@blue-lighten-07;
    transition: background-color 0.2s;
  }

  img.nav-icon
  {
    position: relative;
    top:-3px;
  }

  span, small
  {
    position: relative;
    z-index: 30;
    display: inline-block;
    width: 100%;
    vertical-align: middle;
    line-height: 1em;
    color:@primary-color;
    font-family:@font-family-sans-serif;
    font-weight:900;
  }
  small
  {
    font-size:0.52em;
  }
  sup
  {
    position: absolute;
    top: 20px;
    right: -5px;
    z-index: 20;
    display: block;
    width: 20px;
    height: 20px;
    background: @red-00;
    border-radius: 20px;
    color: @white-00;
    line-height: 20px;
    font-size: 11px;
  }
}

@media(max-width:@screen-xs-max)
{
  .account-badge
  {
    top:0;
    width:45px;
    height:45px;
    line-height:45px;
    font-size:14px;

    &::before
    {
        display:none;
    }
    sup
    {
      top:4px;
      right:-10px;
    }
    small
    {
      font-size:10px;
    }
  }
}

.sign-in-options {
  .dropdown-signup {
    right: 0;
    left: -159px;
    top: 39px;
    width: 300px;
    padding: @line-height-computed;
    padding-bottom: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border: 0;
    background: lighten(@nav-utility, 3%);
    .box-shadow(0 4px 3px fade(#000, 20%));
    overflow: hidden;

    input {
      .placeholder(@muted-text);
    }
  }

  label.error {
    color: lighten(@muted-text, 10%);
  }

  #sign-in-modal {
    top: 0px;
    background: lighten(@nav-utility, 3%);
    padding-top: @line-height-computed;

    .modal-header {

    }
    .modal-body {
      padding-bottom: 0;
    }

    input {
      .placeholder(@muted-text);
    }
  }
}
.account-nav-modal {
  border-bottom: 0;
}
.account-header {
  text-transform: uppercase;
  font-family: @font-family-slab;
  margin-bottom: 0;
}
.account-options {
  @media (max-width: @screen-sm-min) {
    font-size: 18px;
    padding-bottom: 12px;
    a {
      color: @black;
    }
  }
}
.sign-out-option {
  @media (max-width: @screen-sm-min) {
    font-size: 14px;
    width: 85px;
    height: 32px;
    border: 1px solid @taupe-00;
    padding-bottom:0;
    display: flex;
    justify-content: center;
    align-items: center;
    a{
      color: #6B6161
    }
  }
}
.sign-up-block {
  background: @sign-up-block-background;
  margin-right: -(@line-height-computed);
  margin-bottom: 0;
  margin-left: -(@line-height-computed);
  padding: @line-height-computed;
  color: @sign-up-block !important;
  .text-center;
  border-top: 1px solid lighten(@sign-up-block-background, 10%);

  @media (max-width: @screen-sm-min) {
    margin-right: -16px;
    margin-left: -16px;
    position: relative;
    bottom: -1px;
  }

  .btn-sign-up {
    text-transform: uppercase;
    border: none;
    .kern-wide;
    margin-top: (@line-height-computed/2);

  }
}
.signed-in-ok {
  left: -5px;
  top: -8px;
  font-size: 70%;
}
.mobile-primary-nav {
  display: inline-block;
  margin-top: 0;
  margin-right: 14px;
  > a {
    color: @white-00;
    font-family: @font-family-secondary;
    font-weight: bold;

    font-size: 14px;
  }
}
.mobile-sign-in.sign-in-options {
  .visible-xs;
  float:right;
}

.mobile-nav-section-title {
  padding: 5px 15px;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 600;
  color: @blue-lighten-06;
  letter-spacing: 2px;
}

.modal-custom {
  .modal-header {
    border-bottom: 0;
    .close {
      .opacity(.5);
      &:hover {
        .opacity(1);
      }
    }

    .ti-close-x
    {
      visibility:collapse;
      font-size:1px;
    }
    .ti-close-x:before
    {
      visibility:visible;
      font-size: 2rem;
    }
  }
  .modal-body {
    text-align: center;
    padding: 30px 30px 50px;
    h4 {
      margin-bottom: .5em;
      font-size: 2.1em;
      line-height: 1.1em;
      @media (max-width: @screen-xs-max) {
        font-size: 1.8em;
      }
    }
    .lead-2 {
      font-size: 16px;
      line-height: 1.5;
      max-width: 400px;
      display: block;
      margin-left: auto;
      margin-right: auto;
      color: @muted-text;
    }
  }
}

// ---------------------------------------------------- Primary Navigation

.brand-outer-wrapper {

  padding:20px 0 20px 0;
  @media (max-width: @screen-xs-max) {
    padding:10px;

    a i
    {
      display:none;
    }

    .container
    {
      padding:0;
    }
  }

  .brand-wrapper
  {
    float:left;
  }

  // See global-imports-landing.less for the logo.
  .toggle-nav-wrapper {
    .visible-xs;
    .make-xs-column(2);
    .text-right;
    .ti-bars {
      top:0;
    }
  }
  .nav-primary-wrapper {
    float:right;
    @media (max-width: @screen-xs-max) {
      padding-top: 0;
      float: none;
    }
  }
  .brand-nav-primary {
    .make-xs-column(12);
  }

  a:focus {
    outline-color: rgba(255, 255, 255, 0.5);
  }

  #site-menu {
    font-weight: @serif-semibold;
  }
  .primary,
  .secondary {
    li > a > i {
      top: 3px;
      position: relative;
      margin-right: 3px;
    }

    @media (min-width: @screen-sm-min) and (max-width: @screen-md-max) {
      > a:hover {

        > i:after {
          .opacity(1) !important;
        }
      }
    }

    > a {
      .transition(~"all 0.2s ease-in-out");
      border-bottom: 4px solid transparent;

      &:hover {
        border-bottom: 4px solid @blue-lighten-06;
        > i:after {.opacity(1) !important;}

        @media(max-width:@screen-xs-max)
        {
          border-bottom-color:transparent !important;
          &:before
          {
            display: block;
          }
        }
      }
    }




    &.dropdown {
      .header-chevrons;
    }
  }
  .primary {
    font-size: 17px;
  }

  .secondary {
    .hidden-xs;
    .hidden-sm;
    &.first {
      padding-left: 10px;
      margin-left: 10px;
    }
  }
  .divider {
    border-top:1px solid @blue-lighten-01;
    display: block;
    height:1px;
    width: 85%;
    margin: 10px 0;
    .box-sizing(border-box);
    .opacity(.2);
  }
  .dropdown {
    position: relative;
  }

  .dropdown-menu {
    top: 95%;
    > li {
      > a {
        padding-left: 5px;
        padding-right: 5px;
        color: @white-00;
        background: transparent;

        &:hover {
          background-color: @blue-lighten-02;
          color: @white-00;
        }
        &:active {
          background-color: @blue-lighten-02;
          color: @white-00;
        }
        .ti-rss {
          position: relative;
          top: 2px;
        }
      }
    }
  }

  .nav-pills li + li {
    margin-left: 13px;

    &.search {
      margin: 0px;
      @media(min-width:@screen-sm-min)
      {
        margin-left:5px;
      }
      @media(min-width:@screen-lg-min)
      {
        margin-left:10px;
      }
    }

    @media (min-width: @screen-md-min) {
      margin-left: 20px;
    }

  }

  .nav {
    li {

      &.dropdown {
        .header-chevrons;
        > a {
          > i:after {
            bottom: 6px;
          }
        }
      }
    }
    > li > a {
      color: @nav-link;
      padding-top: 5px;
      padding-right: 2px;
      padding-bottom: 5px;
      padding-left: 2px;
      margin: 0;
      border-radius: 0;
      font-family: @font-family-slab;
      font-size: 16px;
      font-weight: normal;
      &:hover {
        background: @primary-color;
      }
      &:focus, &:active {
        color: @white-00;
      }
      .ti-chevron-down {
        position: relative;
        top: 2px;
        width: 9px;
        .opacity(.3);
        @media (min-width: @screen-sm-min) and (max-width: @screen-md-max) {
            display: block;
            clear: both;
            margin: 0 auto;
            top: -6px;
        }
      }

    }

    .open {
      > a {
        border-color: transparent;
      }
    }
  }

  .nav-dropdown {
    background: @nav-dropdown-background;
    border-color: @nav-dropdown-background;
    padding-left: 7px;
    padding-right: 7px;

    &:after {
      content: '';
      position: absolute;
      border-style: solid;
      border-width: 0 7px 7px;
      border-color: @search-background transparent;
      display: block;
      width: 0;
      z-index: 1;
      margin-left: -7px;
      top: -7px;
      left: 90%;
    }

    li + li {
      margin-left: 0;
    }
    li.divider {
      background-color: lighten(@nav-dropdown-background, 5%);
    }
    li.section-title {
      span {
        display: block;
        padding: 8px 5px 0;
        font-size: 10px;
        border-top: 1px solid lighten(@nav-dropdown-background, 15%);
        color: lighten(@nav-dropdown-background, 25%);
        color: @nav-dropdown-text;
        .opacity(.6);
        @media (max-width: @screen-xs-max) {
          border:none;
          padding: 5px 5px 5px 25px;
          margin-top:-8px;
        }
      }
    }
    a {
      font-family: @font-family-sans-serif;
      color: @nav-dropdown-text;
      background: @nav-dropdown-background;

      &:hover {
        color: @nav-dropdown-link-hover;
        background: @nav-dropdown-background;
      }
    }
  }

  li.alt-mobile-nav
  {
    a
    {
      font-size:18px;
      font-weight:600;
      font-family: @font-family-sans-serif;
    }
    &.primary-signin
    {
      box-sizing: border-box;
      padding:15px;
      a
      {
        text-align:center;
        border:1px solid @blue-lighten-02;
        padding:7px !important;
        &:hover
        {
          border:1px solid @blue-lighten-02;
          background:@blue-lighten-02;
          &:before
          {
            display:none;
          }
        }
      }
    }
  }

  .search {
    > .btn {
      padding-top:0;
    }
    .search-icon {
      .transition(color .3s);
      font-size: @font-size-large;
      fill: @search-icon;
      height: 26px;
      width: 26px;
    }
    &:hover {
      .search-icon {
        color: @white-00;
      }
    }
  }
}
.btn-remove,
.btn-danger {
  color: @btn-danger-color !important;
  background: @btn-danger-bg !important;
  border-color: @btn-danger-bg !important;
  &:hover {
    background-color: darken(@btn-danger-bg, 10%);
    border-color: darken(@btn-danger-bg, 10%);
  }
}

.btn-facebook,
.btn-twitter,
.btn-apple {
  color: @white-00;
  font-size: 15px;
  width: 100%;
  display: block;
  text-align: left;
  font-weight: 600;
  &:hover {
    color: @white-00;
  }
  svg {
    fill: @white-00;
    margin-right: 3%;
  }
  .ti-twitter,
  .ti-facebook,
  .ti-apple {
    color: @white-00 !important;
    margin-right: 3%;
  }
}
.btn-facebook     {
  background-color: @facebook;
  &:hover {
    background-color: darken(@facebook, 10%);
  }
}
.btn-twitter      {
  background-color: @twitter;
  &:hover {
    background-color: darken(@twitter, 10%);
  }
}

.btn-apple {
  background-color: @apple;
  &:hover {
    background-color: darken(@apple, 10%);
  }
}

// ----------------- Active states for navigation

.primary > a:before
{
  content:" ";
  display:none;
  position:absolute;
  top:11px;
  bottom:11px;
  left:0;
  width:5px;
  background: @blue-lighten-06;
}

.daily .primary-today,
.resources .primary-resources,
.connect .primary-connect,
.blog .primary-connect,
.more .primary-more,
.about .secondary-about,
.store .secondary-store,
.donate .secondary-donate,
.sermons .primary-sermons,
.broadcasts .primary-broadcasts {
  > a {
    position: relative;
    background: transparent;
    border-bottom: 4px solid @blue-lighten-02 !important;

    @media(max-width:@screen-xs-max)
    {
      border-bottom-color:transparent !important;
      &:before
      {
        display:block;
        background:@blue-lighten-02;
      }
    }
  }
}

.store .secondary-store {
  @media (min-width: @screen-sm-min) {
    &:after {
      .ti;
      margin-left: 1px;
    }
  }
}

.dropdown-search {
  width: 234px;
  padding: 4px;
  background: @blue-00;
  border-color: @blue-darken-02;
  margin-right:30px;

  .form-group {
    width: 100%;
  }
  .form-control#searchCriteria {
    border-radius: 3px;
    color: @text-color;
    font-weight: normal;
    font-family: @font-family-sans-serif;
    background: @search-input-background;
    width: 100%;
  }

  .btn-search {
    background: @search-background;
    color: @white-00;
  }
}
.dropdown-search:after {
  content: '';
  position: absolute;
  border-style: solid;
  border-width: 0 7px 7px;
  border-color: @blue-darken-02 transparent;
  display: block;
  width: 0;
  z-index: 1;
  margin-left: -7px;
  top: -7px;
  left: 90%;
}

@import "mobile-nav.less";

.mobile-bible-form {
  background-color: @muted-background !important;
  padding: 20px !important;
  hr {
    margin-top: 10px;
    margin-bottom: 10px;
    border-top: 1px solid @border-color;
  }
}
.honor-gift-text {
  position: relative;
  background-color: @muted-background;
  border: none;
  color: @muted-text;
  font-size: 1em;
  padding: @grid-gutter-width;
  [class^="ti-"] {
    position: absolute;
    top: 15px;
    right: 15px;
    .rotate(45deg);
  }
}

// --------------------------------------------- Thank You

.thank-you-letter {
  p {
    font-size: 1.2em;
  }
  dl {
    width: 100%;
    .clearfix;
    dt, dd {
      border-bottom: 1px solid @border-color;
      label,
      span.help {
        margin:10px 0 5px;
        display: block;
      }
    }
    dt {
      display: inline-block;
      width: 33%;
    }
    dd {
      display: inline-block;
      width: 66%;

    }
  }
}

.account {
  .available-formats {
    height: auto;
    margin-bottom: 10px;
    text-align: right;
    &.donate-form {
      float: left;

    }
    .format {
      text-align: left;
      margin: 5px 0 5px 10px;
    }
  }
  .resource-submit {
    display: block;
    float: right;
    width: 100%
  }
}

// --------------------------------------------- Available Formats
.available-formats {
  &.donate-form {
    margin-left: 0px;
  }
  min-height: 100px;
  .format {
    min-width: 175px;
    position: relative;
    border-radius: 3px;
    border: 1px solid @available-formats-border;
    float: left;
    margin: 5px 10px 5px 0;
    cursor: pointer;

    &:hover {
      border-color: darken(@available-formats-border, 10%);

      .format-helper {
        background: darken(@available-formats-helper-background, 5%);
      }
    }

    &.selected {
      border-color: @available-formats-border-active;

      .format-helper {
        background: @available-formats-helper-background-active;
        color: @available-formats-helper-color-active;
      }

      .select-status {
        display: block;
        color: @available-formats-helper-background-active;
        .ti-check {
          font-size: 12px;
          width: 12px;
        }
      }
    }
  }
  .format-label {
    padding-left: 7px;
    padding-right: 7px;
    padding-top:10px;
    font-size: 14px;
    line-height: 1.4;
  }
  .select-status {
    font-size: 9px;
    position: absolute;
    right: 5px;
    top: 0px;
    display: none;
  }
  .price-amount {
    font-weight: bold;
    padding: 5px;
  }
  .format-helper {
    background: @muted-background;
    font-size: 11px;
    color: @muted-text;
    padding: 1px 3px;
  }
}

.donation-product-thumb {

  .make-md-column(6);
  img {
    margin: 0 auto;
  }
}
.donation-product-options {

  .make-md-column(6);
}


// ---------------------------------------------------------------- Series

.series-title-wrapper {
  .make-sm-column(12);
}
.series-description-wrapper {
  .make-sm-column(6);
}
.series-media-wrapper {
  padding-top: @line-height-computed;
  .make-sm-column(6);
}
.series-description {
  li {
    padding-bottom: (@line-height-computed * .5);
  }
}
.other-messages-title {
  font-size: @font-size-small;
  font-style: italic;
  padding-top: (@line-height-computed * .5);
  color: @muted-text;
}
.responsive-table-info {
  text-align: right;
  font-style: italic;
  color: @muted-text;
}
.table-series {
  margin-top: 10px;
  width: 100%;
  td,
  th {
    padding: 10px !important;
  }
  th {
    text-transform: uppercase;
    font-size: @font-size-small;
    color: @muted-text;
    background-color: @muted-background;
    .kern-wide;
    border-top: 1px solid transparent !important;
  }
  .archive-title {
    width: 50%;
    font-size: 1em;
  }
  .sermon-id {
    color: @muted-text;
  }
  .distribution-options {
    .text-right;
    background: none;
    white-space: normal;
  }
  a.download {
    display: inline-block;
  }
  a.btn-primary-call {
    color: @white-00;
    text-shadow: none;
    &:hover {
      color: @white-00;
    }
  }
}
.table-footer {
  td, .distribution-options {
    background: darken(@muted-background, 5%);
  }

  &:hover {
    td, .distribution-options {
      background: @muted-background !important;
    }
  }
}

//----------------------------------------------- Panel

.panel {
  padding: 20px;

  &.place-order {
    .panel-body {
      @media (min-width: @screen-md-min) {
        padding: 0px;
      }
    }
  }
  .new-price {
    margin-top: 8px;
  }
  .btn-primary-call {
    display: block;
    width: 100%;
    .box-sizing(border-box);
  }
  .table.table-condensed {
    td, th {
      padding: 4px 0;
    }
    .price {
      text-align: right;
    }
  }
  &.panel-faq {
    padding: 0 0 @grid-gutter-width;
    border-bottom: 1px solid @muted-background;
    margin-bottom: @grid-gutter-width;
    .box-shadow(none);
    &:last-child {
      border-bottom: none;
    }
  }
}

.product-thumb-cart {
  min-width: 100px;
  text-align: center;
  img {
    .img-responsive();
  }
  @media (min-width: @screen-sm-min) {
    .pull-left;
    margin-right: 15px;
  }
}

.popover-title {
  font-family: @font-family-base !important;
  letter-spacing: 0 !important;
}
.popover-content {
  font-size: 14px;
}

// ------------------------------------------------------------------------- Events
.events {
  .event_information {
    > p {
      margin: 0;
      padding: 0;
      font-size: 16px;
      line-height: 1.5;
    }
  }
}

// ------------------------------------------------------------------------- Job Postings
.jp_position {

  padding: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid @white-darken-05;
}

// ------------------------------------------------------------------------- Comments

.comments-label {
  color: @muted-text;

  a {
    color: @muted-text;
  }
}

/* Fresh Widget Tweaks */
#freshwidget-button {
  @media (max-width: @screen-xs-max) {
    display: none !important;
  }
  > a, a:hover, a:active {
    text-decoration: none !important;
  }
}
#freshwidget-button > a.freshwidget-theme {
  .box-shadow(none);
}

.group-options {
  .make-row();
}
.group-option {
  .make-xs-column(3);
  &:last-child {
    .make-xs-column(6);
  }
}

/* -------------------------------------------------------------------------- Brand Icons */
.brand-icons() {
  font-size: 20px;
  text-align: center;
  width: 26px;
}

.main-content-wrapper {
  ul [class^="ti-"] {
    .brand-icons;
  }
  .ti-facebook    { color: @facebook; }
  .ti-twitter     { color: @twitter; }
  .ti-pinterest   { color: @pinterest; }
  .ti-soundcloud  { color: @soundcloud; }
  .ti-youtube     { color: @youtube; }
  .ti-linkedin    { color: @linkedin; }
  .ti-android     { color: @android; }
  .ti-windows     { color: @windows; }
  .ti-apple       { color: @apple; }
  .ti-instagram   { color: @instagram;}
}

// ------------------------------------------------------ Filters

.filters {
  position: relative;
  .form-group {
    display: inline-block;
    label {
      display: inline-block;
      font-weight: normal;
      font-family: @font-family-secondary;
      font-style: italic;
    }
    .btn-group {
      display: inline-block;
      margin-right: 10px;
    }
    &.form-group-show {
      margin-right: 20px;

    }
  }
}

// ------------------------------------------------------ Alerts

.alert {
  ul {
    margin: 0;
    padding:0;
    > li {
      margin:0;
      font-size: 14px;
    }
  }
}

// ------------------------------------------------------ Podcast

.distribution-options {
  padding-top: 2px;
  padding-bottom: 2px;
  background-color: @muted-background;
  .clearfix;
}
.podcast-description {
  .readmore {
    font-size: 16px;
    text-transform: uppercase;
    [class^="ti-"] {
      font-size: 14px;
    }
  }
}
.subscribe-dropdown {
  margin-top:0 !important;
}
.distribution-options {
  white-space: nowrap;
}
.readmore {
  font-size: 14px;
  text-transform: uppercase;
  font-family: @font-family-secondary;
}
.resources_sermons_single-player {
  .by-line-wrapper {
    .make-sm-column(6);
  }
  .distribution-options-wrapper {
    .make-sm-column(6);
  }
}

// ------------------------------------------------------------------------- Podcast

.podcast-container, .content-container {
  padding-top: @line-height-computed;
  padding-bottom: (@line-height-computed * 2);

  @media (max-width: @screen-xs-max) {
    padding-top: 0;
  }
}
.podcast-title-support {
  .make-row();
}

.media-player-wrapper {
  .audio-container {
    img {
      @media (min-width: @screen-md) {
        max-width: 605px;
      }
    }
  }
}
.podcast-description-wrapper {
  padding-top: 20px;
  @media (min-width: @screen-md) {
    padding-top: 0px;
  }
}
.media-options-support {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: @line-height-computed;
  margin-bottom: @line-height-computed;
}
.by-line-wrapper {
  .make-xs-column(6);
  color: @muted-text;
  @media (max-width: @screen-xs-max) {
    font-size: @font-size-small;
    .img-circle {
      width: 25px;
      margin-right: 4px;
    }
  }
  .date-preached {
    font-size: .9em;
  }
  .pipe {
    margin-left: 5px;
    margin-right: 5px;
  }
}
.distribution-options-wrapper {
  .make-xs-column(6);
  .text-right;

  .download, .purchase, .favorites-toggle, .add-queue-popover {
    color: @taupe-darken-01;

    @media (max-width: @screen-xs-max) {
      .btn-xs;
    }
  }
}

.top-space {
  margin-top: 2rem;
}

.btn-wide {
  width: 100%;
}

.btn-subscribe-options button {
  @media (max-width: @screen-xs-max) {
    .btn-xs;
  }
}
.btn-secondary {
  color: @btn-default-color;
  background-color: @white-darken-04;
  border-color: @white-darken-04;
  font-family: @font-family-sans-serif;

  &.active {
    background-color: @taupe-darken-01;
    color: @white-darken-02;
    border-color: @taupe-darken-01;
  }
  &:hover {
    opacity: 0.8;
  }
}

.btn-transparent {
  color: @taupe-darken-03;
  background-color: transparent;
  border-color: @taupe-lighten-04;
  font-family: @font-family-sans-serif;
  border-radius: 4px;

  &.active {
    //background-color: @taupe-darken-01;
    color: @taupe-darken-01;
    border-color: @taupe-darken-01;
  }
  &:hover {
    opacity: 0.8;
  }
}

.btn-alternate {
  background-color: @blue-lighten-02;
  color: @white-00;
  border-color: @blue-lighten-02;
  font-family: @font-family-sans-serif;
  border-radius: 4px;

  &.active {
    background-color: @blue-lighten-02;
    color: @white-00;
    border-color: @blue-lighten-02;
  }
  &:hover {
    background-color: @blue-lighten-02;
    color: @white-00;
    border-color: @blue-lighten-02;
    opacity: 0.8;
  }
}

.btn-alert {
  background-color: @red-00;
  color: @white-00;
  border-color: @red-00;
  font-family: @font-family-sans-serif;
  border-radius: 4px;

  &.active {
    background-color: @red-lighten-01;
    color: @white-00;
    border-color: @red-lighten-01;
  }
  &:hover {
    background-color: @red-lighten-01;
    color: @white-00;
    border-color: @red-lighten-01;
    opacity: 0.8;
  }
}

.btn-submit {
  .tfl-btn-submit;
}


.scripture-reference {
  blockquote {
    border-left:0;
    margin:0;
    padding-bottom:0;
    p {
      font-family: @font-family-secondary;
      font-style: italic;
      font-size: 18px;
      margin: .5em 0;
      line-height: 1.7;
    }
  }
  cite {
    display: block;
    text-align: right;
    margin-right: 20px;
    font-family: @font-family-secondary;
    line-height: 1.7;
  }
}
.resources_devotionals_index .split-content-wrapper-first {
  div.scripture-reference {
    border-left: 5px solid @muted-background;
  }
  .devotional-ctas {
    padding-top: @grid-gutter-width;
    .btn {
      width: 100%;
      white-space: normal;
      letter-spacing: 0;
      i {
        top: 3px;
      }
    }
    .hs-btn-wrap {
      margin-bottom: 10px;
      white-space: normal;
      width: 100%;
      text-align: center;
      display: block;
    }
    .syndicate-ad {
      margin-bottom: 10px;
      white-space: normal;
      width: 100%;
      text-align: center;
    }
  }
}
.scripture-reference, .scripture-reference a {
  .ti-bookmark-o {
    margin-right: 4px;
    color: @muted-text;
  }
}
.scripture-reference {
  margin-bottom: @grid-gutter-width/2;
  > li {
    margin-bottom:0 !important;
  }
  .ti-bookmark-o {
    position: relative;
    top: 2px;
  }
}

.scripture-reference.pull-left {
  margin-right: 10px;
  display: inline-block;
}

.podcast-display-mini {

  .distribution-options {
    .text-right;
    background: @muted-background;
    margin-bottom: @line-height-computed;
    a {
      color: @muted-text;

      &:hover {
        color: @bold-link;
        text-decoration: none;
      }
    }
  }
  .section-title {
    padding-bottom: 0;
    font-size: @font-size-small;
  }
  .podcast-description {
    .small;
  }
}

.deflist {
  border: 1px solid @muted-background;
  .make-row;
  padding-top: 10px;
  padding-bottom: 10px;
  dt, dd {
    padding: 5px 10px;
    margin-bottom:0 !important;

    p, label {
      margin: 0;
    }
  }
  dt {
    .make-sm-column(5);
    .make-lg-column(3);
    clear: both;


  }
  dd {
    .make-sm-column(7);
    .make-lg-column(9);
    border-left: 1px solid @muted-background;
    address {
      font-size: inherit;
      margin-bottom:0;
    }
  }
}

.standard-form {
  h3 {
    color: @muted-text;
    text-transform: uppercase;
    font-size: 16px;
    letter-spacing: 1px;
    margin-top: 2em;
  }
}

.disabled-by-default-form {
  button.save {
    display: none;
  }

  &.enable {
    button.save {
      display: inline-block;
    }
    a.change {
      display: none;
    }
  }
}
.marker {
  position: absolute;
  top: -100px;
  &#photos {
    top:-140px;
  }
}
.sidebar-nav.transparent {
  background: transparent !important;
  border: none;
  border-left: 1px solid @muted-background;
}

.related-products,
.related-resources {
  margin-top: @grid-gutter-width*2;
  h3 {
    font-size: 18px;
    text-transform: uppercase;
    color: @muted-text;
    margin-top: 1em;
    margn-bottom: 25px;
  }
}

.related-products {

  .cards {
    .make-row;
    list-style: none;
    padding-left: 0;
    li {
      padding-left: 0;
      .make-sm-column(4);

      .card-inner {
        max-height: 240px !important;
        min-height: auto;
        strong {
          display: block;
          line-height: 1.3;
          font-weight: 700;
          font-size: 16px;
          font-family: @font-family-secondary;
        }
        img {
          .img-responsive;
          height: auto;
          max-height: 104px;
        }
      }
    }
  }
}

.related-resources {

  .well {
    border: none;

  }

  .default-product {
    padding-left: 5px;
    padding-right: 5px;
  }
  .cards {
    .row {
      margin-left: -5px;
      margin-right: -5px;
    }
    .card {
      margin-bottom: 0;
    }
  }

}

.topics-list {
  li {
    display: inline-block;
    &.divider {
      &:after {
        content: ", ";
      }
      &:last-child:after {
        content: "";
      }
    }
  }
}

// ------------------------------------------------------ Site Refresh Quarter Layout
.quarter-layout {
  @media (min-width: @screen-sm-min) {
    padding-top: 18px;
  }
  .main-content-support:before,
  .header-support:after {
    border: none;
  }
  .header-support {
    .section-title {
      margin: 0;
    }
  }
  .main-content-support {
    padding-top: 24px;

    @media (min-width: @screen-md-min) {
      .main-content-wrapper {
        width: 75%;
      }
      .sidebar-wrapper {
        width: 25%;
        padding-left: 15px;
      }
    }

    .main-content-wrapper {
      padding-top: 0 !important;

      .main-content__heroblock {
        max-width: 100%;
        margin-bottom: 65px;

        .main-content__heroimg {
          width: 100%;
          border-radius: 10px;
        }
      }
    }
  }
}

// Hacks
// ---------------------------------------------------------

// All Firefox (only)
@-moz-document url-prefix() {
  .small-caps {
     font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }
}

// Old Browser Hack
.ie8, .ie9, .oldie {
  display: none;
}
html.oldie .oldie {
  display: block;
}
html.oldie {
  .form-control {
    color: @muted-text;
  }
}

// This targets IE 10+
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .saved_payment_options__wrapper--slim .payment_method__wrapper {
    height: 100%;
  }
}



// Social Nav
// ---------------------------------------------------------

.social-share {
  @media (min-width: @screen-sm-min) {
    float: right;
    position: absolute;
    right:0;
    top: 0;
  }

  .btn {
    text-transform: uppercase;
    color: @muted-text !important;
    &:active {
      .box-shadow(none);
    }
    [class^="ti-"], [class*=" ti-"] {
      top: 0 !important;
    }
  }
  .dropdown-menu {
    background-color: @white-00;
    border-radius: 0;
    left: auto;
    right: 0;
    .box-shadow(0 0 25px rgba(0,0,0,.2));
    padding: 10px;
    width: auto;
    min-width: 156px;
    border-radius: 1px;
    border-color: @white-00;
    &:after {
      content: "";
      position: absolute;
      top:-10px;
      right: 10px;
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid @white-00;
    }
    > li {
      display: inline-block;
      text-align: center;
      > a {
        padding: 1px 0 0 0;
        width: 30px;
        height: 30px;
        line-height: 30px;
        font-size: 20px;
        text-align: center;
        display: inline-block;
        color: @muted-text;
        [class^="ti-"], [class*=" ti-"] {
          width: 20px;
          height: 20px;
          position: relative;
          top: 2px;
        }
        &:hover {
          color: @link-color;
        }
      }
    }
  }
  @media (max-width: @screen-xs-max) {
    display: none;
    position: fixed;
    bottom: 0;
    left:0;
    z-index: 99999;
    background: @white-00;
    width: 100%;
    .box-shadow(0 -3px 20px rgba(0,0,0,.12));
    text-align: center;
    .btn {
      padding: 12px;
      display: block;
    }
    .dropdown-menu {
      top: -139%;
      right: auto;
      left: 50%;
      margin-left: -60px;
      &:after {
        display: none;
      }
      &:before {
        content: "";
        position: absolute;
        top: auto;
        bottom: -10px;
        left: 43%;
        right: auto;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid @white-00;
      }
    }
  }

}


.carousel-product {
  .carousel {
    height: 350px;
    background: transparent;
    padding-bottom: 50px;
  }
  .carousel-fade {
    .carousel-inner {
      .item {
          opacity: 0;
          height: 330px;
          a {
            display: flex;
            flex-direction: column;
            justify-content: center;
            background: @white-00;
            height: 330px;
          }
          img {
            width: auto;
            height: auto;
          }
          .transition-property(opacity);
      }
      .active { opacity: 1 }
      .active.left,
      .active.right {
          left: -80%;
          opacity: 0;
          z-index: 1;
      }
      .next.left,
      .prev.right { opacity: 1 }
    }
    .carousel-control { z-index: 2 }
  }
  .carousel-control {
      width: 40px;
      height: 330px;
      font-size: 100px;
      background: transparent;
      border: none;
      line-height: 100%;
      .opacity(.3);
      &:hover {
        .opacity(1);
      }
      [class^="ti-"] {
        top: 36%;
        font-size: 20px;
        position: absolute;
        margin-top: 0;
        color: @muted-text;
        text-shadow: none;
      }
      &.right {
        right: -20px;
      }
      &.left {
        [class^="ti-"] {
          left: -20px;
          right: auto;
        }
      }
  }

  .carousel-caption {
    padding: 10px;
    text-align: center;
    position: absolute;
    left: 0;
    background: url('/static/images/product-carousel-title-bg.png') repeat-x top;
    color: @text-color;
    text-shadow: none;
    width: 100%;
    bottom:-10px;
  }
  .carousel-caption h4 {
      font-size: 17px;
      font-weight: 600;
      margin:0;
  }




  @media only screen and (max-width:767px) {
      body { padding: 0 !important }
      .carousel { margin-bottom: 0 }
      .hero-unit { padding-bottom: 60px }
      .hero-unit p { font-size: 16px }
  }
  .carousel-container {
      width: 90% !important;
      padding-left: 10%;
      margin-top: 0;
  }
}

// ------------------------------------------------------------- Alexa Instructions

.alexa-instructions {
  blockquote {
    border-left: none;
    background: @border-color;
    margin-top:0;
  }
  hr {
    margin: 4em 0 !important;
  }
  .btn-primary-call {
    white-space: normal;
    padding: 15px 30px;
    margin-left: auto;
    margin-right: auto;
    display: inline-block;
  }
  .call-wrap {
    text-align: center;
    padding: 2em 0;
  }
}

// ------------------------------------------------------------- Modal Overrides

.modal {
  &.modal-tiny {
    @media (min-width: @screen-sm-min) {
      .box-shadow(0 0 80px rgba(0,0,0,.15));
      background: @white-00;
    }
    .close {
      font-size: 18px;
    }
    h3 {
      margin-top: 0;
      font-size: 24px;
      letter-spacing: .04em;
    }
    h4 {
      display: none;
    }
    .media {
      margin-right: 0;
      @media (max-width: @screen-xs-max) {
        width: 100%;
      }
    }
    .media-left {
      @media (min-width: @screen-sm-min) {
        float: left;
      }
    }

    .modal-dialog {
      margin: auto auto;
      position: absolute;

      @media (max-width: @screen-xs-max) {
        width: calc(~'100% - 20px');
        bottom: 10px;
        left: 10px;
        .box-shadow(0 0 80px rgba(0,0,0,.25));
      }
      @media (min-width: @screen-sm-min) {
        width: 100%;
        height: 100%;
      }
    }
    .modal-content {
      box-shadow: none;
    }
    .modal-body {

      @media (max-width: @screen-xs-max) {
        padding: 15px;
        text-align: center;
      }
      @media (min-width: @screen-sm-min) {
        padding: 0 0 10px;
        text-align: left;
      }
      p {
        line-height: 1.5;
        margin-bottom: 10px;
        &.existing-tp {
          text-align: center;
          clear: both;
          margin-bottom: 0;
        }
      }
      .btn {
        white-space: normal;
      }
    }

    .media-object {
      max-width: 169px;
      @media (max-width: @screen-xs-max) {
        margin: 0 auto;
      }
    }
  }
}

.show-tiny-modal {
  .modal-backdrop.in {
    @media (min-width: @screen-sm-min)  {
      height: 0;
      width: 0;
    }
  }
  .modal-tiny {
    @media (min-width: @screen-sm-min)  {
      top: auto;
      bottom: 5%;
      left: 4%;
      right: auto;
      width: 600px;
      height: 260px;
      z-index: 10;
      overflow: visible;
    }
  }
}

// ---------------------------------------------- Overrides

// ---------------------------------------------- Static Page Styles
//
// These styles are for specific static template views under `misc_pages.views`,
// which set a body class based on the URL slug:
//
body.alexa,
body.applewatch,
body.google-home {
  img.hero {
    margin-bottom: 20px;
  }
}
body.alexa,
body.google-home {
  .section-title,
  #subnav-toggle {
    display: none !important;
  }
  .article-title-wrapper {
    h1.article-title {
      margin-top: 30px;
    }
  }
}

body.podcast {
  .list-group {
    max-width:500px;
  }
  h3 {
    padding-top: 4em;
    color: #fff;
    background-size: cover;
  }
  h3.daily-program {
    background: #000 url(https://www.truthforlife.org/static/uploads/AboutAlistairLong.jpg) no-repeat right top;
  }
  h3.sermons {
    background: #000 url(https://f.hubspotusercontent40.net/hubfs/331596/_landing/_ToKnowChrist/AccentAlistair.jpg);
  }

  h3.ab_devotional {
    background: #ddd;
    padding: 2px;
    img {
      max-height: 100%;
      max-width: 100%;
      object-fit: contain;
    };
  }

}

body.subscribe {
  .main-content-wrapper {
    .choices.list-unstyled {
      li {
        line-height: 1em;
        [class^='ti-'] {
          position: relative;
          top: 3px;

        }
      }
    }
  }
  h2 {
    font-size: 25px;
  }
  .help-text {
    font-size: 16px;
    color: @muted-text;
    i {
      font-style: normal;

    }
  }
  .panel {
    box-shadow: none;
  }
  .ti-alexa-mark {
    color: @amazon;
  }
  .ti-tunein {
    color: @tunein;
  }
  .ti-audionow {
    color: @audionow;
  }
  .ti-google-home-mark,
  .ti-googleplay,
  .ti-itunes,
  .ti-audionow,
  .ti-oneplace,
  .ti-siriusxm {
    color: @muted-text;
  }
  img.ti-tiktok {
    display: inline-block;
    width: 26px;
    height: 20px;
    vertical-align: text-top;
  }
  img.ti-whatsapp {
    display: inline-block;
    width: 26px;
    height: 20px;
    vertical-align: text-top;
  }
  .ti-tablet {
    margin-left: -20px;
    margin-right: auto;
    position: relative;
  }
  img.ti-firetv {
    width: 30px !important;
    padding-right: 10px;
  }
  .form-inline .form-control {
    display: inline-block;
    max-width: 150px;
  }
}
body.about {
  img.inset-left {
    float: left;
    margin-right: 25px;
    margin-bottom: 10x;
    border: none;
  }
}
body.about-alistair-begg {
  img.banner {
    display: block;
    width: 100%;
  }

  iframe.column-video {
    width: 100%;
    height: 250px;
  }
  @media(max-width:992px) {
    .main-content-wrapper p.lead {
      font-size: 20px;
    }
  }

  .main-content-wrapper ul li {
    margin: 0;
    font-size: 1em;
  }

  h2 span {
    text-transform: uppercase;
    font-size: 50%;
    color: #988b82;
    margin-left: 20px;
  }

  h2.section-title {
    margin-top: 3rem;
    color: #000;
    font-size: 2rem;
    text-transform: none;
  }

  p.section-title,a.section-title {
    color: #988b82;
    font-size: 14px;
    font-family: 'Sanchez',Georgia,"Times New Roman",Times,serif;
    font-weight: bolder;
  }

  .hbspt-form {
    padding: 2rem;
    background: #f2f1ed;
  }

  .hbspt-form input {
    display: block;
    width: 100%;
    padding: 5px 20px 5px 20px;
    border: 1px solid #e0e2d5;
    font-family: 'Sanchez',Georgia,"Times New Roman",Times,serif;
  }

  .hbspt-form input[type="submit"] {
    color: #fff;
    font-weight: bolder;
    color: #fff;
    background-color: #ff9d00;
    border-color: #ff8a00;
  }

  .hbspt-form label {
    display: none;
  }

  .hbspt-form .hs-error-msgs label {
    display: block;
  }

  .hbspt-form .hs_error_rollup {
    display: none;
  }
}
body.jointheteam {
  .font-normal {
    font-weight: normal;
  }
  .dark-slate {
    color: #333333;
  }
}
body.stewardship {
  img.top {
    -ms-interpolation-mode: bicubic;
    margin: 15px;
    float: right;
    max-width: 300px;
    width: 50%;
  }
  .charity-logo-toprated img {
    max-width: 76px;
    height: auto;
  }
  .charity-logo-eig img {
    max-width: 60px;
    height: auto;
  }

}
body.volunteer {
  .font-normal {
    font-weight: normal;
  }
  .space-below {
    margin-bottom: 17.2043px;
  }
  .space-above {
    margin-top: 17.2043px;
  }
  .dark-slate {
    color: #333333;
  }
}
// ---------------------------------------------- End Static Page Styles


// Adds tiny size for iPhoneSE/5+ and before. DEPRECATED
.visible-tiny {
  display: none;
}
@media (max-width: @screen-tiny-max) {
  .visible-tiny {
    display: block !important;
  }
}
// Adds xxs size for iPhoneSE/5+ and before.
.visible-xxs {
  display: none;
}
@media (max-width: @screen-xxs-max) {
  .visible-xxs {
    display: block !important;
  }
}


.change_box {
  background: @muted-background;
  border: 1px solid @border-color;
  border-radius: 2px;
  overflow: auto;
  padding: 20px;
  cursor: pointer;
  .change_link {
    height: 100%;
    vertical-align: top;
    text-align: right;
    cursor: pointer;
  }
  &:hover {
    background: lighten(@muted-background, 1.5%);
    border-color: lighten(@border-color, 1.5%);
  }
}


.container {
  .container-fixed();

  @media (min-width: @screen-sm-min) {
    width: 98%
  }
  @media (min-width: @screen-md-min) {
    width: 95%
  }
  @media (min-width: @screen-lg-min) {
    width: @container-lg;
  }
}



// Sitewide Truthpartner visual identifiers
.account-badge.truthpartner {
  &::before {
    border-color: @tp-yellow;
    @media (max-width:@screen-sm) {
      display: block;
    }
  }
  &::after {
    background-color: @blue-darken-01;
    background-image: url("/static/images/responsive/truthpartner_thistle.svg");
    background-size: contain;
  }
  span {
    color: @blue-lighten-09;
  }
}

a:hover {
  .account-badge.truthpartner {
    &::after {
      background-color: @blue-00;
      background-image: url("/static/images/responsive/truthpartner_thistle.svg");
      background-size: contain;
      transition: background-color fast linear;
    }

  }
}

input[type=checkbox],
input[type=radio] {
  accent-color: @ui-blue-00;
}


// Displays item on page load then removes class after small duration
.js-showonload {
  opacity: 1 !important;
}

.hide-from-view {
  height: 0;
  overflow: hidden;
  opacity: 0;
}

.color_row {
  width: 100%;
  border: 1px solid @black;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  margin-bottom: 30px;
  justify-content: stretch;

  .color_item {
    flex: 1 1 1;
    min-width: 50px;
    height: 100px;
    border: 1px solid @black;
  }
}

// --------------- additional.less
// # FIXME: organize into appropriate subfiles.
/* Styles used on Bible search page */

.esv p {
  margin: 0 0 23px;
}

/* Styles used on Bible OT/NT display page */
.holder-info-block {
  margin-bottom: 19px;
}

.holder-box {
  margin: -4px 0 34px;
  overflow: hidden;
  width: 100%;
}

.info-block1 {
  margin: 0 0 -9999px;
  padding: 21px 40px 9999px 0;
  width: 293px;
}

.info-block2 {
  border-left: 1px solid @border-color;
  margin: 0 0 -9999px;
  padding: 21px 0 9999px 19px;
  width: 250px;
}

.span-3 {
  width: 45%;
  float: left;
}

/* Styles use in footer email subscribe form */
#loader_subscribe_form {
margin-left: 30%;
display: none;
}

#loader_modal_login_form {
margin-left: 30%;
display: none;
}

/* Styles for previous/next buttons on broadcast detail page */
ul.btns {
list-style: none;
list-style-position: inside;
margin-left: -45px;
}

/* Styles for donation form */
li.wants-donation-product {
display: inline;
}

li.wants-donation-product:first-child {
margin-left: -35px;
margin-right: 30px;
}

/* Styles for donation form errors */
p.error {
font-weight: bold;
color: #993300;
}

ul.errorlist li {
list-style: none;
font-weight: bold;
color: #993300;
}

/* Styles used in the header login form */
#loader_login_form {
margin-left: 30%;
display: none;
}

#login-error {
color: @white-00;
font-weight: bold;
}