#body .joyride-tip-guide {
  background: lighten(@muted-background, 10%);
  color: @muted-text;
  box-shadow: 0 3px 2px rgba(0, 0, 0, 0.3);
  z-index: 1000;

  span.joyride-nub.top {
    border-bottom-color: lighten(@muted-background, 10%);
  }

  h2 {
    color: @primary-color;
    font-size: @font-size-h5;
  }

  .joyride-next-tip {
    .btn-primary-call;
  }
  a.joyride-close-tip {
    color: @muted-text !important;

    &:hover {
      color: @muted-text !important;
    }
  }
}
