#site-wrapper {
  position: relative;
  overflow: hidden;
  width: 100%;
}

#site-canvas {
  width: 100%;
  height: 100%;
  position: relative;
  left:0;
  background-color: @white-00;
}
@media(max-width:@screen-xs-max){
  .show-nav #site-canvas {
    left:-270px;
  }

}

#site-menu {
  position: relative;

  @media (max-width: @screen-xs-max) {
    width: 270px;
    position: absolute;
    top: 0;
    right: -270px;
    background-color: @blue-00;
    z-index: 505;
    padding-top: 75px;
    min-height: 800px;

    .search {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    }

    .dropdown-search {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      border-radius: 0;
      box-shadow: none;
      border: none;
      background: none;
      padding: 7px;
      margin: 0;
    }
    .dropdown-search:after {
      display: none;
    }
    .form-group {
      float: left;
      margin: 5px 0;
      padding: 0 5px 0 7px;
    }

    .form-control#searchCriteria {
      box-shadow: 0px 1px 5px inset rgba(0, 0, 0, 0.2);
      background: @blue-darken-01;
      color:@blue-lighten-09;
      border:none;
      &::placeholder
      {
        color:@blue-lighten-09;
      }
    }

    .primary, .secondary {
      display: block;
      .clearfix;
      width: 100%;
      margin: 0;

      a {
        border-radius: 0;
        padding: 7px 7px 7px 16px;
        width: 100%;
      }
    }

    li.divider {
      margin: 10px auto;
      width: 100%;
      border-top-color:@blue-lighten-02;
      opacity: 1;
    }
    li.section-title {
      margin-bottom: 0;
      margin-top:0;

      > span {
        border-top: none;
        padding-top: 8px;
        display: block;
        margin: 0 31px;
        padding-left: 0;
        padding-right: 0;
      }
    }
    .nav-dropdown:after {
      display: none;
    }
    &.nav-pills {

      > li.active {
        > a, a:hover, a:focus {

        }
      }
    }
    .open .nav-dropdown {
      display: block;
      position: relative;
      width: 100%;
      border-radius: 0;
      box-shadow: none;
      border: none;
      padding: 0;
      margin: 0;
      background:none;

      .divider {
        display: none;
      }

      li {
        border-bottom: none;
      }

      a {
        padding: 10px 0 10px 31px;
        background: transparent;
        font-size:14px;
        font-weight:600;
      }
    }
    .xs-secondary {
      padding-top:10px;
      > a {
        color: @nav-utility-link;
        font-family:@font-family-sans-serif;
        font-size: 14px;
        font-weight:bold;
      }
    }
  }
}
#searchCriteria {
  background: lighten(@brand-primary, 5%);
  color: @white-00;
}

@media(max-width:@screen-xs-max)
{
  .toggle-nav
  {
    cursor: pointer;
    .nav-icon--show{ display:inline }
    .nav-icon--hide{ display:none; }
  }

  body.nav-open
  {
    background-color: @blue-00;

    #site-wrapper
    {
      max-height:1170px;
    }
    .toggle-nav
    {
      .nav-icon--show{ display:none }
      .nav-icon--hide{ display:inline; }
    }
  }
}
// ------------------------------------------------------------------------- Full Width

.wide-container {
  padding-top: @line-height-computed;
}

.wide-content-support {
  .make-row();
  padding-bottom: (@line-height-computed * 2);
}

.wide-content-support:before {
  content: "";
  display: block;
  border-top: 1px solid @border-color;
  margin-left: 15px;
  margin-right: 15px;

  @media (min-width: @screen-sm-min) {
    margin-right: 15px;
  }

  @media (min-width: @screen-md-min) {
    margin-right: 15px;
  }

  @media (min-width: @screen-lg-min) {
    margin-right: 15px;
  }
}

.wide-content-wrapper {
  .make-sm-column(12);
  padding-top: @line-height-computed;

  @media (min-width: @screen-lg-min) {
    padding-right: 15px;
  }
}

.serp-container {
  padding-top: @line-height-computed;

  .results {
    margin: 0 0 @line-height-computed 0;
  }

  .section-title {
    display: block !important;
  }
}
.search-sub-form-wrapper {
  margin-top: 24px;
}
.search-sub-form {
  .well;
  border-radius: @border-radius-base;
  border: 1px solid darken(@well-border, 5%);
  .input-wrapper {
    .make-xs-column(9);
    padding: 0;
    &:before {
      content: "";
      .ti;
      background: url(/static/images/_svgs/svg-search.svg) no-repeat center center;
      background-size: contain;
      height: 30px;
      width: 30px;
      position: absolute;
      top:50%;
      left:5px;
      margin-top: -15px;
      z-index: 2;
      .opacity(.3);
    }
  }
  &#stationfinder-country-form {
    .input-wrapper {
      &:before {
        display: none;
      }
    }
  }
  .form-group {
    margin: 0;

  }
  .form-control {
    .input-lg;
    height: 47px;
    padding-left: 34px;
    @media (max-width: @screen-xs-max) {
      .input-sm;
      padding-left: 34px;
    }
  }
  .btn-wrapper {
    .make-xs-column(3);
  }
  .btn-search {
    text-transform: uppercase;
    .kern-wide;
    .btn-lg;
    .btn-block;
    .tfl-btn-primary;

    @media (max-width: @screen-xs-max) {
      .btn-sm;
    }
  }
}

@media (min-width: @screen-xs-max)
{
  .serp-container aside
  {
    float:right;

    h5
    {
      margin-top:50px;
    }
  }

}
@media (max-width: @screen-xs-max)
{
  ul.serp-filter-list
  {
    overflow:hidden;
    li
    {
      margin: 2px;
      padding-right: 14px;
      display: block;
      float: left;
      background: @muted-background;

      .checkbox
      {
        margin-top:4px;
        margin-bottom:0;

        input[type='checkbox']
        {
          width: 13px;
          margin-left: -15px;
          margin-top: 4px;
        }
      }
    }
  }
}

.label-serp-type {
  background: @label-serp-type-background;
}
.station_header {
  .label-serp-type {
    font-size: 100%;
    padding: .3em .6em;
  }
  .search-intro {
    margin-top: 4px;
  }
}

.stationfinder {
  .station_results_container {
    h3 {
      margin-top: 10px;
      margin-bottom: 4px;
    }
  }
  .results {
    margin-top: 0;
    font-size: 100%;
    margin-bottom: @grid-gutter-width;
    font-style: normal;
    strong {
      background-color: yellow;
    }
  }
  .search-result {
    margin-bottom: @grid-gutter-width;
  }
  .station_location {
    display: block;
    margin: 4px 0;
  }
  .search-intro {
    h4 {
      font-size: 16px;
      margin-bottom: 4px;
      color: @muted-text;
    }
  }
  .table {
    .radio-schedule-list {
      width: 20%;
      font-weight: 500;
    }
  }
  @media (max-width: @screen-xs-max) {
    .sidebar-nav {
      display: block !important;
      border-left: none;
      padding-left:0;
    }
  }
}
