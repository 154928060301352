.Account
{
    display:block;
    position:relative;
    max-width:1024px;
    margin:1em 0 1em 0;

    .Overview
    {
        overflow:hidden;

        .Phase
        {
            display:block;
            position:relative;
            float:left;
            padding-left:10px;
            padding-bottom:5px;
            margin-bottom:2px;
            text-align:left;
            text-transform:uppercase;
            font-weight:bolder;
            font-size:80%;
            hyphens: manual;

            &::after
            {
                display:block;
                position: absolute;
                top:0;
                left:2px;
                width:0;
                height:200px;
                border-left:2px solid #ddd;
                content:" ";
            }
        }
        .Phase.Create
        {
            width:75%;
        }
        .Phase.TP
        {
            position:relative;
            width:25%;

            &[onclick]
            {
                cursor:pointer;
                &::before
                {
                    display: inline-block;
                    left: 0;
                    top: 0;
                    width: 15px;
                    height: 15px;
                    margin-right: 3px;
                    border-radius: 50%;
                    background: #ddd;
                    color: #333;
                    line-height: 15px;
                    text-align:center;
                    content: "?";
                }
            }
        }
    }

    .Steps
    {
        position:relative;
        display:block;
        overflow:hidden;
        clear:both;
        margin:0;
        padding:0;
        border:0;

        .Step
        {
            position:relative;
            display:block;
            float:left;
            width:25%;
            height:50px;
            margin:0;
            padding:0;
            border:0;
            background:#0EC3FD;
        }
        .Active.Step
        {
            background:#DED620;
        }
        .Complete.Step
        {
            background:#c8c8c8;
        }
        .Icon
        {
            display:block;
            position:absolute;
            top:50%;
            left:100%;
            z-index:100;
            width:0px;
            height:0px;
            &::before
            {
                content:" ";
                display:block;
                position:absolute;
                top:-10px;
                border-left:10px solid @white-00;
                border-top:10px solid transparent;
                border-bottom:10px solid transparent;
            }
            &::after
            {
                content:" ";
                display:block;
                position:absolute;
                top:-10px;
                left:-3px;
                border-left:10px solid #0EC3FD;
                border-top:10px solid transparent;
                border-bottom:10px solid transparent;
            }
        }
        .Active .Icon::after
        {
            border-left-color:#DED620;
        }
        .Complete .Icon::after
        {
            border-left-color:#c8c8c8;
        }
        .Label
        {
            position:absolute;
            display:block;
            width:100%;
            height:100%;
            padding-top:14px;
            border-left:2px solid @white-00;
            border-top:3px solid @white-00;
            border-bottom:3px solid @white-00;
            color: @white-00;
            text-align:center;
            font-size:14px;
            line-height:1.1em;
        }
        .Active .Label
        {
            border-top:0px;
            border-bottom:0px;
            font-weight:bolder;
        }
        .Complete .Label::before
        {
            display: block;
            position: absolute;
            top: -9px;
            left: 47%;
            width: 20px;
            height: 20px;
            background: @white-00;
            border-radius: 50%;
            color: #ccc;
            text-align: center;
            font-weight: bolder;
            line-height: 26px;
            content: "✓ ";

            @media(max-width:600px)
            {
                top:-5px;
                width:12px;
                height:12px;
                line-height:14px;
                font-size:9px;
            }
        }

        @media(max-width:600px)
        {
            .Step
            {
                height:48px;
            }
            .Label
            {
                font-size:12px;
                padding-top:7px;
            }
        }
    }

    .Info
    {
        display:none;
        border-bottom: 1px solid @white-darken-05;
        p
        {
            margin: 0 auto;
            max-width: 600px;
            font-size: 15px;
            color: @grey-darken-01;
            text-align: center;
        }
    }
}
