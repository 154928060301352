
/** main header */
.brand-outer-wrapper {
    .brand-wrapper {
  
      .brand-link {

        position: relative;
        left: 60px;

        @media (max-width: 1070px) {
          background-image: url(/static/images/responsive/logo-stacked.svg);
          background-size: 130px 44px;
          background-position: 0 center;
          width: 135px;
          height: 44px;
        }

        @media (max-width: @screen-sm-max) {
          background-image: url(/static/images/responsive/logo-stacked.svg);
          background-size: 130px 44px;
          background-position: 0 center;
          width: 135px;
          height: 44px;
        }

        @media (max-width: @screen-xs-max) {
          background-size: 90px 33px;
          background-position: 0px center;
          height: 31px;
          width: 44px;
        }

        @media (max-width: 800px) {
          background-size: 90px 44px;
          background-position: 0 center;
          width: 90px;
          height: 44px;
        }

        @media (max-width: 400px) {
          background-size: 70px 44px;
          background-position: 0 center;
          width: 122px;
          height: 44px;
        }

        &::before
        {
          content: " ";
          display: block;
          position: absolute;
          left: -73px;
          top: -14px;
          width: 65px;
          height: 65px;
          background-repeat: no-repeat;
          background-image: url(/static/images/responsive/30-logo-white.svg);

          @media(max-width: 1070px)
          {
            left: -72px;
          }

          @media(max-width: 800px)
          {
            left: -53px;
            top: 0px;
            width: 43px;
            height: 43px;
          }

        }
  
      }
    }
  }
  
/** main footer */
.footer-logo-wrapper
{
    /* */
    a.footer-logo
    {
        position: relative;
        margin-right: 60px;
        min-width: 100px;

        &::after
        {
            position: absolute;
            top: -10px;
            right: -60px;
            display: block;
            content: " ";
            width: 50px;
            height: 50px;
            background-image: url(/static/images/responsive/30-logo-white.svg);
        }
    }
}

/** donation header */
a.donation-header__link
{
    margin-left: 60px;
    position: relative;

    &::before
    {
        position: absolute;
        top: -5px;
        left: -60px;
        display: block;
        content: " ";
        width: 50px;
        height: 50px;
        background-image: url(/static/images/responsive/30-logo-white.svg);
    }
}

/** checkout header */
body.checkout .header-simple a.header-simple__logo
{
  margin-left: 60px;
  position: relative;

  &::before
  {
      position: absolute;
      top: -10px;
      left: -60px;
      display: block;
      content: " ";
      width: 50px;
      height: 50px;
      background-image: url(/static/images/responsive/30-logo-white.svg);
  }

  @media(max-width: 400px)
  {
    width:180px;
  }

}

/** become a tp header */
.landing.truthpartners .Intro a[href^='/']
{
    position:relative;
    padding-left: 50px;

    &::before
    {
        position: absolute;
        top: -23px;
        left: 0;
        display: block;
        content: " ";
        width: 50px;
        height: 50px;
        background-image: url(/static/images/responsive/30-logo-blue.svg);
    }
}

/** login header */
#site-canvas .login.login--header a
{
    position:relative;
    padding-left: 60px;

    &::before
    {
        position: absolute;
        top: -10px;
        left: 0;
        display: block;
        content: " ";
        width: 50px;
        height: 50px;
        background-image: url(/static/images/responsive/30-logo-blue.svg);
    }
}