// WARNING: editing this file may break players on the site.
// This file is currently a less-ified version of the stock CSS that comes with
// mediaelement.js player.


// 11222D - Primary background color of the bar
// 5b6d78 - completed part of the progress bar
// 3a4c57 - total length of the file (progress bar background)
// 003859 @ 70%  - overlay on video with text/close
// E0F4FF - icon colors and player text color for the overlay and time/progress (edited)



@color_1: #333;
@color_2: #111;
@color_3: rgba(33, 248, 248, 1);
@color_darkblue: #11222D;
@color_black: rgba(0,0,0,1);
@color_white: rgba(255,255,255,1);
@color_lighttext: #ddd;
@color_darktext: rgba(51, 51, 51, 0.75);
@color_controlstext: #E0F4FF;

@color_rail_light: #3a4c57; // mejs-time-total
@color_rail_medium: #4B5D68; // mejs-time-loaded
@color_rail_dark: #5b6d78; // mejs-time-current

@font_family_1: 'Helvetica', Arial, serif;
@background_color_1: rgb(200, 200, 200);
@background_color_2: rgba(255, 255, 255, 0.4);
@border_color_1: #eee transparent transparent;
@mejs_asset_path: "/static/js/responsive/lib/mediaelement/icons/";

/* Accessibility: hide screen reader texts (and prefer "top" for RTL languages).
Reference: http://blog.rrwd.nl/2015/04/04/the-screen-reader-text-class-why-and-how/ */
/* Hide native play button and control bar from iOS to favor plugin button */
/* Start: LAYERS */
@-webkit-keyframes mejs-loading-spinner {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes mejs-loading-spinner {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
/* End: LAYERS */
/* Start: CONTROL BAR */
/* :focus for accessibility */
/* End: CONTROL BAR */
/* Start: Time (Current / Duration) */
/* End: Time (Current / Duration) */
/* Start: Play/Pause/Stop */
/* End: Play/Pause/Stop */
/* Start: Progress Bar */
@-webkit-keyframes buffering-stripes {
    from {
        background-position: 0 0;
    }
    to {
        background-position: 30px 0;
    }
}
@keyframes buffering-stripes {
    from {
        background-position: 0 0;
    }
    to {
        background-position: 30px 0;
    }
}
/* End: Progress Bar */
/* Start: Fullscreen */
/* End: Fullscreen */
/* Start: Mute/Volume */
/* End: Mute/Volume */
/* Start: Track (Captions and Chapters) */
/* End: Track (Captions and Chapters) */
/* Start: Error */
/* End: Error */
.mejs-offscreen {
    border: 0;
    clip: rect( 1px, 1px, 1px, 1px );
    -webkit-clip-path: inset( 50% );
    clip-path: inset( 50% );
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    word-wrap: normal;
}
.mejs-container {
    background: #000;
    box-sizing: border-box;
    font-family: @font_family_1;
    position: relative;
    text-align: left;
    text-indent: 0;
    vertical-align: top;
    * {
        box-sizing: border-box;
    }
    video {
        &::-webkit-media-controls {
            -webkit-appearance: none;
            display: none !important;
        }
        &::-webkit-media-controls-panel {
            -webkit-appearance: none;
            display: none !important;
        }
        &::-webkit-media-controls-panel-container {
            -webkit-appearance: none;
            display: none !important;
        }
        &::-webkit-media-controls-start-playback-button {
            -webkit-appearance: none;
            display: none !important;
        }
    }
    &:focus {
        outline: none;
    }
}
.mejs-fill-container {
    height: 100%;
    width: 100%;
    background: transparent;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    .mejs-container {
        height: 100%;
        width: 100%;
    }
}
.mejs-iframe-overlay {
    height: 100%;
    position: absolute;
    width: 100%;
}
.mejs-embed {
    background: #000;
    height: 100%;
    margin: 0;
    overflow: hidden;
    padding: 0;
    width: 100%;
    body {
        background: #000;
        height: 100%;
        margin: 0;
        overflow: hidden;
        padding: 0;
        width: 100%;
    }
}
.mejs-fullscreen {
    overflow: hidden !important;
}
.mejs-container-fullscreen {
    bottom: 0;
    left: 0;
    overflow: hidden;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 1000;
    .mejs-mediaelement {
        height: 100% !important;
        width: 100% !important;
    }
    video {
        height: 100% !important;
        width: 100% !important;
    }
    // display controls in fullscreen
    .mejs-controls {
      bottom: 0;
      //background: @color_black;
      //color: @color_lighttext;
    }
}
.mejs-background {
    left: 0;
    position: absolute;
    top: 0;
}
.mejs-mediaelement {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
}
.mejs-poster {
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    left: 0;
    position: absolute;
    top: 0;
    z-index: 1;
}
&:root {
    .mejs-poster-img {
        display: none;
    }
}
.mejs-poster-img {
    border: 0;
    padding: 0;
}
.mejs-overlay {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    left: 0;
    position: absolute;
    top: 0;
    &:hover {
        >.mejs-overlay-button {
            background-position: -80px -39px;
        }
    }
}
.mejs-layer {
    z-index: 1;
}
.mejs-overlay-play {
    cursor: pointer;
    background: transparent url('@{mejs_asset_path}overlay-pause.svg') center no-repeat;
    &.overlay-playing {
        background: transparent url('@{mejs_asset_path}overlay-play.svg') center no-repeat;
    }
}
.mejs-overlay-button {
    height: 80px;
    width: 80px;
}
.mejs-overlay-loading {
    height: 80px;
    width: 80px;
}
.mejs-overlay-loading-bg-img {
    -webkit-animation: mejs-loading-spinner 1s linear infinite;
    animation: mejs-loading-spinner 1s linear infinite;
    background: transparent url('@{mejs_asset_path}mejs-controls.svg') -160px -40px no-repeat;
    display: block;
    height: 80px;
    width: 80px;
    z-index: 1;
}



// ALTERED LESS

.mejs-controls {
    //bottom: -40px;
    color: @color_controlstext;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 40px;
    left: 0;
    list-style-type: none;
    margin: 0;
    padding: 0 10px;
    position: absolute;
    width: 100%;
    z-index: 3;
    &:not([style*='display: none']) {
        background: @color_darkblue;
    }
}
.mejs-button {
    font-size: 10px;
    height: 40px;
    line-height: 10px;
    margin: 0;
    width: 32px;
    >button {
        border: 0;
        cursor: pointer;
        display: block;
        font-size: 0;
        height: 20px;
        line-height: 0;
        margin: 10px 6px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        text-decoration: none;
        width: 20px;
        &:focus {
            outline: dotted 1px #999;
        }
    }
}
.mejs-time {
    font-size: 10px;
    height: 40px;
    line-height: 10px;
    margin: 0;
    width: 32px;
    box-sizing: content-box;
    color: @color_controlstext;
    font-size: 11px;
    font-weight: bold;
    height: 24px;
    overflow: hidden;
    padding: 16px 6px 0;
    text-align: center;
    width: auto;
}
.mejs-time-rail {
    font-size: 10px;
    height: 40px;
    line-height: 10px;
    margin: 0;
    width: 32px;
    direction: ltr;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    height: 40px;
    margin: 0 10px;
    padding-top: 10px;
    position: relative;
    &:hover {
        .mejs-time-handle-content {
            -webkit-transform: scale(1);
            -ms-transform: scale(1);
            transform: scale(1);
        }
    }
    .mejs-time-handle-content {
        &:focus {
            -webkit-transform: scale(1);
            -ms-transform: scale(1);
            transform: scale(1);
        }
        &:active {
            -webkit-transform: scale(1);
            -ms-transform: scale(1);
            transform: scale(1);
        }
    }
}

.mejs-time-total {
  border-radius: 8px;
  overflow: hidden;
}
.mejs-time-loaded {
  border-radius: 8px;
}

.mejs-container-keyboard-inactive {
    a {
        outline: 0;
        &:focus {
            outline: 0;
        }
    }
    button {
        outline: 0;
        &:focus {
            outline: 0;
        }
    }
    [role=slider] {
        outline: 0;
        &:focus {
            outline: 0;
        }
    }
}
.mejs-time-rail:focus,
.mejs-time-slider span:focus {
        outline: none;
}

.mejs-play {
    >button {
      background: transparent url('@{mejs_asset_path}icons8-play.svg') no-repeat center center;
    }
}
.mejs-pause {
    >button {
      background: transparent url('@{mejs_asset_path}icons8-pause.svg') no-repeat center center;
    }
}
.mejs-skip {
    >button {
      background: transparent url('@{mejs_asset_path}icons8-skip.svg') no-repeat center center;
    }
}
.mejs-previous {
    >button {
      background: transparent url('@{mejs_asset_path}icons8-previous.svg') no-repeat center center;
      transform: rotate(180deg);
    }
}
.mejs-replay {
    >button {
      background: transparent url('@{mejs_asset_path}icons8-refresh.svg') no-repeat center center;
    }
}
.mejs-mute {
    >button {
      background: transparent url('@{mejs_asset_path}icons8-volume.svg') no-repeat center center;
    }
}
.mejs-unmute {
    >button {
      background: transparent url('@{mejs_asset_path}icons8-no_audio.svg') no-repeat center center;
    }
}
.mejs-fullscreen-button {
    >button {
      background: transparent url('@{mejs_asset_path}icons8-full_screen.svg') no-repeat center center;
    }
}
.mejs-miniplayer-button {
    >button {
      background: transparent url('@{mejs_asset_path}icons8-miniplayer.svg') no-repeat center center;
    }
}
.mejs-defaultplayer-button {
    display: none;
    >button {
      background: transparent url('@{mejs_asset_path}icons8-default_size.svg') no-repeat center center;
    }
}
.mejs-miniplayer-close {
  display: none;
  >button {
    background: transparent url('@{mejs_asset_path}icons8-delete_sign.svg') no-repeat center center;
  }
}

.mejs-queue-button {
  >button {
    background: transparent url('@{mejs_asset_path}icons8-queue-list.svg') no-repeat center center;
  }
}

.mejs-container-fullscreen {
    .mejs-controls {
        .mejs-fullscreen-button {
            >button {
                background: transparent url('@{mejs_asset_path}icons8-default_size.svg') no-repeat center center;
            }
        }
        .mejs-miniplayer-button,
        .mejs-queue-button {
            display: none;
        }
    }
}

#scroll-player {
    .mejs-controls {
        .mejs-miniplayer-button {
            display: none;
        }
        .mejs-defaultplayer-button {
            display: block;
        }
        .mejs-fullscreen-button {
            display: none;
        }
        .mejs-miniplayer-close {
          display: block;
        }
    }
}
.mejs-mediaelement {
  overflow: hidden;
}
.mejs-time-total {
    border-radius: 8px;
    cursor: pointer;
    display: block;
    height: 10px;
    position: absolute;
    background: @color_rail_light;
    margin: 5px 0 0;
    width: 100%;
    border-radius: 8px;
}
.mejs-time-buffering {
    border-radius: 2px;
    cursor: pointer;
    display: block;
    height: 10px;
    position: absolute;
    -webkit-animation: buffering-stripes 2s linear infinite;
    animation: buffering-stripes 2s linear infinite;
    background: -webkit-linear-gradient(135deg, rgba(255, 255, 255, 0.4) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.4) 50%, rgba(255, 255, 255, 0.4) 75%, transparent 75%, transparent);
    background: linear-gradient(-45deg, rgba(255, 255, 255, 0.4) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.4) 50%, rgba(255, 255, 255, 0.4) 75%, transparent 75%, transparent);
    background-size: 15px 15px;
    width: 100%;
    left: 0;
    -webkit-transform: scaleX(0);
    -ms-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: 0 0;
    -ms-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transition: 0.15s ease-in all;
    transition: 0.15s ease-in all;
    width: 100%;
    -webkit-transform: scaleX(1);
    -ms-transform: scaleX(1);
    transform: scaleX(1);
}
.mejs-time-loaded {
    border-radius: 2px;
    cursor: pointer;
    display: block;
    height: 10px;
    position: absolute;
    background: @color_rail_medium;
    left: 0;
    -webkit-transform: scaleX(0);
    -ms-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: 0 0;
    -ms-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transition: 0.15s ease-in all;
    transition: 0.15s ease-in all;
    width: 100%;
}
.mejs-time-current {
    border-radius: 8px;
    cursor: pointer;
    display: block;
    height: 10px;
    position: absolute;
    background: @color_rail_dark;
    left: 0;
    -webkit-transform: scaleX(0);
    -ms-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: 0 0;
    -ms-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transition: 0.15s ease-in all;
    transition: 0.15s ease-in all;
    width: 100%;
}
.mejs-time-float {
    border-radius: 2px;
    cursor: pointer;
    display: block;
    height: 10px;
    position: absolute;
    background: #eee;
    border: solid 1px #333;
    bottom: 100%;
    color: @color_darktext;
    display: none;
    height: 17px;
    margin-bottom: 9px;
    position: absolute;
    text-align: center;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    width: 36px;
}
.mejs-time-hovered {
    border-radius: 2px;
    cursor: pointer;
    display: block;
    height: 10px;
    position: absolute;
    background: rgba(255, 255, 255, 0.5);
    z-index: 10;
    left: 0;
    -webkit-transform: scaleX(0);
    -ms-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: 0 0;
    -ms-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transition: 0.15s ease-in all;
    transition: 0.15s ease-in all;
    width: 100%;
    -webkit-transition: height 0.1s cubic-bezier(0.44, 0, 1, 1);
    transition: height 0.1s cubic-bezier(0.44, 0, 1, 1);
}
.mejs-time-float-current {
    border-radius: 2px;
    cursor: pointer;
    display: block;
    height: 10px;
    position: absolute;
    display: block;
    left: 0;
    margin: 2px;
    text-align: center;
    width: 30px;
}
.mejs-time-float-corner {
    border-radius: 2px;
    cursor: pointer;
    display: block;
    height: 10px;
    position: absolute;
    border: solid 5px #eee;
    border-color: @border_color_1;
    border-radius: 0;
    display: block;
    height: 0;
    left: 50%;
    line-height: 0;
    position: absolute;
    top: 100%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    width: 0;
}
.mejs-time-marker {
    border-radius: 2px;
    cursor: pointer;
    display: block;
    height: 10px;
    position: absolute;
}
.mejs-time-handle-content {
    background: rgba(255, 255, 255, 0.9);
    border: 4px solid transparent;
    cursor: pointer;
    left: 0;
    position: absolute;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
    z-index: 11;
    border: 4px solid rgba(255, 255, 255, 0.9);
    border-radius: 50%;
    height: 10px;
    left: -7px;
    top: -4px;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
    width: 10px;
}
.mejs-time-hovered.negative {
    background: rgba(0, 0, 0, 0.2);
}
.mejs-time-hovered.no-hover {
    -webkit-transform: scaleX(0) !important;
    -ms-transform: scaleX(0) !important;
    transform: scaleX(0) !important;
}
.mejs-time-handle {
    border: 4px solid transparent;
    cursor: pointer;
    left: 0;
    position: absolute;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
    z-index: 11;
}
.mejs-long-video {
    .mejs-time-float {
        margin-left: -23px;
        width: 64px;
    }
    .mejs-time-float-current {
        width: 60px;
    }
}
.mejs-broadcast {
    color: @color_1;
    height: 10px;
    position: absolute;
    top: 15px;
    width: 100%;
}
//.mejs-fullscreen-button {
//    >button {
//        background-position: -80px 0;
//    }
//}
//.mejs-unfullscreen {
//    >button {
//        background-position: -100px 0;
//    }
//}
//.mejs-mute {
//    >button {
//        background-position: -60px 0;
//    }
//}
//.mejs-unmute {
//    >button {
//        background-position: -40px 0;
//    }
//}
.mejs-volume-button {
    position: relative;
    >.mejs-volume-slider {
        -webkit-backface-visibility: hidden;
        background: rgba(50, 50, 50, 0.7);
        border-radius: 0;
        bottom: 100%;
        display: none;
        height: 115px;
        left: 50%;
        margin: 0;
        position: absolute;
        -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        transform: translateX(-50%);
        width: 25px;
        z-index: 1;
    }
    &:hover {
        border-radius: 0 0 4px 4px;
    }
}
.mejs-volume-total {
    background: rgba(255, 255, 255, 0.5);
    height: 100px;
    left: 50%;
    margin: 0;
    position: absolute;
    top: 8px;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    width: 2px;
}
.mejs-volume-current {
    background: rgba(255, 255, 255, 0.9);
    left: 0;
    margin: 0;
    position: absolute;
    width: 100%;
}
.mejs-volume-handle {
    background: rgba(255, 255, 255, 0.9);
    border-radius: 1px;
    cursor: ns-resize;
    height: 6px;
    left: 50%;
    position: absolute;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    width: 16px;
}
.mejs-horizontal-volume-slider {
    display: block;
    height: 36px;
    position: relative;
    vertical-align: middle;
    width: 56px;
}
.mejs-horizontal-volume-total {
    background: rgba(50, 50, 50, 0.8);
    border-radius: 2px;
    font-size: 1px;
    height: 8px;
    left: 0;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 16px;
    width: 50px;
}
.mejs-horizontal-volume-current {
    background: rgba(255, 255, 255, 0.8);
    border-radius: 2px;
    font-size: 1px;
    height: 100%;
    left: 0;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 0;
    width: 100%;
}
.mejs-horizontal-volume-handle {
    display: none;
}
.mejs-captions-button {
    position: relative;
    >button {
        background-position: -140px 0;
    }
    >.mejs-captions-selector {
        background: rgba(50, 50, 50, 0.7);
        border: solid 1px transparent;
        border-radius: 0;
        bottom: 100%;
        margin-right: -43px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        right: 50%;
        visibility: visible;
        width: 86px;
    }
}
.mejs-chapters-button {
    position: relative;
    >button {
        background-position: -180px 0;
    }
    >.mejs-chapters-selector {
        background: rgba(50, 50, 50, 0.7);
        border: solid 1px transparent;
        border-radius: 0;
        bottom: 100%;
        margin-right: -43px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        right: 50%;
        visibility: visible;
        width: 86px;
        margin-right: -55px;
        width: 110px;
    }
}
.mejs-captions-selector-list {
    list-style-type: none !important;
    margin: 0;
    overflow: hidden;
    padding: 0;
}
.mejs-chapters-selector-list {
    list-style-type: none !important;
    margin: 0;
    overflow: hidden;
    padding: 0;
}
.mejs-captions-selector-list-item {
    color: @color_1;
    cursor: pointer;
    display: block;
    list-style-type: none !important;
    margin: 0 0 6px;
    overflow: hidden;
    padding: 0;
    &:hover {
        background-color: @background_color_1 !important;
        background-color: @background_color_2 !important;
    }
}
.mejs-chapters-selector-list-item {
    color: @color_1;
    cursor: pointer;
    display: block;
    list-style-type: none !important;
    margin: 0 0 6px;
    overflow: hidden;
    padding: 0;
    &:hover {
        background-color: @background_color_1 !important;
        background-color: @background_color_2 !important;
    }
}
.mejs-captions-selector-input {
    clear: both;
    float: left;
    left: -1000px;
    margin: 3px 3px 0 5px;
    position: absolute;
}
.mejs-chapters-selector-input {
    clear: both;
    float: left;
    left: -1000px;
    margin: 3px 3px 0 5px;
    position: absolute;
}
.mejs-captions-selector-label {
    cursor: pointer;
    float: left;
    font-size: 10px;
    line-height: 15px;
    padding: 4px 10px 0;
    width: 100%;
}
.mejs-chapters-selector-label {
    cursor: pointer;
    float: left;
    font-size: 10px;
    line-height: 15px;
    padding: 4px 10px 0;
    width: 100%;
}
.mejs-captions-selected {
    color: @color_3;
}
.mejs-chapters-selected {
    color: @color_3;
}
.mejs-captions-translations {
    font-size: 10px;
    margin: 0 0 5px;
}
.mejs-captions-layer {
    bottom: 0;
    color: @color_1;
    font-size: 16px;
    left: 0;
    line-height: 20px;
    position: absolute;
    text-align: center;
    a {
        color: @color_1;
        text-decoration: underline;
    }
}
.mejs-captions-layer[lang=ar] {
    font-size: 20px;
    font-weight: normal;
}
.mejs-captions-position {
    bottom: 15px;
    left: 0;
    position: absolute;
    width: 100%;
}
.mejs-captions-position-hover {
    bottom: 35px;
}
.mejs-captions-text {
    background: rgba(20, 20, 20, 0.5);
    box-shadow: 5px 0 0 rgba(20, 20, 20, 0.5), -5px 0 0 rgba(20, 20, 20, 0.5);
    padding: 0;
    white-space: pre-wrap;
    * {
        background: rgba(20, 20, 20, 0.5);
        box-shadow: 5px 0 0 rgba(20, 20, 20, 0.5), -5px 0 0 rgba(20, 20, 20, 0.5);
        padding: 0;
        white-space: pre-wrap;
    }
}
.mejs-container.mejs-hide-cues {
    video {
        &::-webkit-media-text-track-container {
            display: none;
        }
    }
}
.mejs-overlay-error {
    position: relative;
    >img {
        left: 0;
        max-width: 100%;
        position: absolute;
        top: 0;
        z-index: -1;
    }
}
.mejs-cannotplay {
    color: @color_1;
    font-size: 0.8em;
    position: relative;
    a {
        color: @color_1;
        font-size: 0.8em;
        display: inline-block;
        padding: 0 15px;
        width: 100%;
    }
    p {
        display: inline-block;
        padding: 0 15px;
        width: 100%;
    }
}
