// Global Landing Imports

.brand-outer-wrapper {
  .brand-wrapper {

    .brand-link {
      display: block;
      background-repeat: no-repeat;
      margin-top: 0;
      margin-bottom: 3px;
      text-indent: -10000px;

      background-image: url(/static/images/responsive/truth-for-life.svg);
      background-size: 300px 44px;
      background-position: 0px 0px;
      height: 44px;
      width: 300px;

      @media (max-width: @screen-sm-max) {
        background-image: url(/static/images/responsive/truth-for-life-notag-wt.svg);
        background-size: 140px 14px;
        background-position: 0px 10px;
        height: 34px;
        width: 140px;
      }

      @media (max-width: @screen-xs-max) {
        background-image: url(/static/images/responsive/truth-for-life-square.svg);
        background-size: 44px 31px;
        background-position: 0px 4px;
        height: 31px;
        width: 44px;
      }
      
    }
  }
}

.print-only { display: none; }
.hidden-print {
  @media print {
    display: none;
  }
}
