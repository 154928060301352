.ui-datepicker {
  background: @datepicker-background;
  padding: 5px 5px 0 5px;
  box-shadow: 0 3px 10px fade(#000, 20%);
  width: auto;
  margin-top: 10px;

  &:after {
    content: '';
    position: absolute;
    border-style: solid;
    border-width: 0 7px 7px;
    border-color: #FFFFFF transparent;
    display: block;
    width: 0;
    z-index: 1;
    margin-left: -7px;
    top: -7px;
    left: 50%;
  }

  .ui-datepicker-header {
    padding: 10px 0;
    background: @datepicker-header-background;
    font-weight: bold;
    text-transform: uppercase;
    .kern-wide;
    color: @datepicker-header;
  }

  .ui-datepicker-prev-hover,
  .ui-datepicker-next-hover {
    top: 2px;
  }
  .ui-datepicker-prev {
    left: 2px;
  }
  .ui-datepicker-next {
    right: -5px;
    .text-right;
  }
  .ui-datepicker-prev-hover {
    left: 2px;
  }
  .ui-datepicker-next-hover {
    right: -5px;
  }

  .ui-datepicker-prev, .ui-datepicker-next {
    font-weight: normal;
    font-size: 26px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 14px;
    color: @datepicker-prev-next;

    &:hover {
      text-decoration: none;
      cursor: pointer;
      color: darken(@datepicker-prev-next, 10%);
    }
  }

  // .ui-datepicker-prev:before {
  //   font-family: FontAwesome;
  //   content: "\f104";
  // }
  // .ui-datepicker-next:before {
  //   font-family: FontAwesome;
  //   content: "\f105";
  // }
  .ui-datepicker-prev:before {
    display: block;
    content: "";
    background-image: url('/static/images/_svgs/svg-angle-left.svg');
    background-size: 14px 14px;
    height: 14px;
    width: 14px;
    top: 8px;
  }
  .ui-datepicker-next:before {
    display: block;
    content: "";
    background-image: url('/static/images/_svgs/svg-angle-right2.svg');
    background-size: 14px 14px;
    height: 14px;
    width: 14px;
  }

  .ui-datepicker-calendar {
    background: @datepicker-background;

    th {
      background: @datepicker-daynames-background;
      .kern-wide;
      padding: 5px;
      text-transform: uppercase;
      font-weight: normal;
      font-size: 12px;
      color: @datepicker-daynames;
    }
    td {
      background: @datepicker-header-background;
      padding: 1px;
      border-left: 1px solid @datepicker-border;
      border-bottom: 1px solid @datepicker-border;
    }

    a {
      padding: 5px 13px;
      color: @datepicker-days;
    }
    .ui-state-default {
      padding: 5px 13px;
    }

    .ui-state-highlight {
      background: @datepicker-highlight-background;
      font-weight: bold;
      color: @datepicker-highlight;
    }

    .ui-datepicker-today {
      background: @datepicker-highlight-background;
    }

    .ui-datepicker-unselectable {
      color: lighten(@datepicker-daynames, 10%) !important;
    }
  }
}
