@import "../includes/global-imports.less";
@font-size-base:  19px;
@import "../includes/structure.less";

// ------------------------------------------------------------- barriers

.app {
  background: #333  url('/static/landing/app/appBackground.jpg') no-repeat center center fixed;
  background-size: cover;
  @media (max-width: @screen-xs-max) {
    background-attachment: scroll;

  }
  a {
    color: #A7CE3D;
  }
  #site-canvas {
    background: transparent;
    color: #fff;
  }
  .app-header {
    @media (min-width: @screen-md-min) {
      padding-top: @grid-gutter-width*3;
    }
  }
  .content {
    .container {
      @media (min-width: @screen-sm-min) {
        max-width: 1000px;
        padding: @grid-gutter-width*3 @grid-gutter-width*3 10px;
      }
      background: fade(#000, 50%);
    }
  }
  .landing-footer {
    padding-top: @grid-gutter-width;
    text-align: center;
    .container {
      @media (min-width: @screen-sm-min) {
        max-width: 1000px;
      }
    }
  }
  .brand-link {
    display: block;
    background-size: 300px 44px;
    background-repeat: no-repeat;
    height: 44px;
    width: 300px;
    margin-top: 0;
    margin-bottom: 3px;
    text-indent: -10000px;
    // background-image: url(/static/images/responsive/truth-for-life.svg);
    background-image: url(/static/images/responsive/truth-for-life.svg);
    background-size: 225px 44px;
    height: 44px;
    width: 225px;
    margin-top: 3px;

    @media (max-width: @screen-xs-max) {
      margin-top: 15px;
    }
    @media (min-width: @screen-md-min) {
      background-image: url(/static/images/responsive/truth-for-life.svg);
      background-size: 300px 44px;
      height: 44px;
      width: 300px;
    }

    @media (min-width: @screen-lg-min) {
      background-size: 300px 44px;
      height: 44px;
      width: 300px;
    }
  }
  article {
    header {
      @media (max-width: @screen-xs-max) {
        text-align: center;
      }
      position: relative;
      .social-share {
        position: absolute;
        .btn {
          @media (min-width: @screen-sm-min) {
            color: #fff !important;
          }
          [class^="ti-"] {
            height: 18px;
            width: 18px;
          }
        }
        .dropdown-menu {
          min-width: 125px;
        }
        @media (max-width: @screen-xs-max) {
          position: fixed;
          bottom: 0;
          z-index: 9999;
        }
      }
    }
    h1 {
      font-size: 50px;
      @media (max-width: @screen-xs-max) {
        font-size: 30px;
      }
    }
    .lead {
      color: #fff;
      font-size: 30px;
      @media (max-width: @screen-xs-max) {
        font-size: 20px;
      }
    }

  }
  .btn-app-badge {
    img {
      max-width: 180px;
      height: auto;
      margin-bottom: 8px;
      @media (max-width: 320px) {
        max-width: 49%;
      }
      @media (min-width: 321px) and (max-width: @screen-xs-max) {
        max-width: 150px;
      }
      @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
        max-width: 49%;
      }
    }
  }
  .btn-apple-store {
    position: relative;
    z-index: 2;
  }
  .btn-android svg {
    width: 184px;
    position: relative;
    margin-bottom: -10px;
  }
  .btn-windows svg {
    width: 156px;
    position: relative;
    margin-bottom: -10px;
    margin-top: -50px;
    position: relative;
  }

  .carousel {
    background: url('/static/landing/app/iphone-bg.png') no-repeat 0 0;
    background-size: 100% auto;
    max-width: 326px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: @grid-gutter-width;
    display: block;
    @media (max-width: @screen-xs-max) {
      max-width: 296px;
      padding: 17px;
      height: 667px;
      padding-top: 72px;
    }
    @media (min-width: @screen-sm-min) {
      padding: 12px;
      height: 527px;
      padding-top: 50px;
    }
    @media (min-width: @screen-md-min) {
      padding: 18px;
      height: 727px;
      padding-top: 73px;
    }
    @media (min-width: @screen-lg-min) {
      padding: 19px;
      height: 737px;
      padding-top: 77px;
    }
    img {
      border-radius: 4px;

    }
    .carousel-control {
      background: transparent;
      color: #fff;
      opacity: 1;
      font-size: 30px;
      span {
        display: block;
        height: 40px;
        width: 40px;
        top: 50%;
        margin-top: -50px;
        line-height: 40px;
        text-align: center;
        position: absolute;
      }
      &.right {
        right: -40px;
      }
      &.left {
        left: -40px;
      }
    }
    .carousel-indicators {
      li {
        border-color: #aaa;
      }
    }
  }
}
footer {
  font-size: .8em;
  @media (max-width: @screen-xs-max) {
    #gradient > .vertical (rgba(0,0,0,.5), rgba(0,0,0,0));
    padding: @grid-gutter-width*2 10%;
  }
}
